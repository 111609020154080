import { Workspace, WorkspaceDetails } from "@dewo/app/graphql/types";
import { Card, Divider, Tabs, Typography } from "antd";
import React, { ComponentType, FC, useCallback, useMemo } from "react";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useRouter } from "next/router";
import { Tab } from "@dewo/app/components/Tab";
import { WorkspaceSettingsPaymentMethod } from "./WorkspaceSettingsPaymentMethod";
import { WorkspaceSettingsGeneral } from "./general/WorkspaceSettingsGeneral";
import { WorkspaceSettingsTokenGating } from "./WorkspaceSettingsTokenGating";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { WorkspaceRBAC } from "../../rbac/workspace/WorkspaceRBAC";
import { DiscordIcon } from "@dewo/app/components/icons/Discord";
import { WorkspaceSettingsTaskTemplates } from "./WorkspaceSettingsTaskTemplates";
import { WorkspaceDiscordIntegrations } from "./integrations/WorkspaceDiscordIntegrations";
import { WorkspaceSettingsDangerZone } from "./WorkspaceSettingsDangerZone";
import { NotionIcon } from "@dewo/app/components/icons/Notion";
import { WorkspaceNotionIntegrations } from "./integrations/WorkspaceNotionIntegrations";
import { WorkspaceGithubIntegrations } from "./integrations/WorkspaceGithubIntegrations";
import {
  AppstoreOutlined,
  CreditCardOutlined,
  FileOutlined,
  GithubOutlined,
  LockOutlined,
  SafetyCertificateOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { MiddleDot } from "@dewo/app/components/MiddleDot";
import { useLocalState } from "@dewo/app/util/hooks";

interface Props {
  workspace: WorkspaceDetails;
}

interface SettingTab {
  key: string;
  icon: JSX.Element;
  title: string | JSX.Element;
  component: ComponentType<{ workspace: WorkspaceDetails }>;
}

export const useWorkspaceSettingsTabs = (workspace?: Workspace) => {
  const canRemoveWorkspace = usePermission("delete", workspace);

  const [showDiscordDot, setShowDiscordDot] = useLocalState<"true" | "false">(
    `Workspace.${workspace?.id}.showDiscordDot`,
    "true"
  );
  const [showAccessDot, setShowAccessDot] = useLocalState<"true" | "false">(
    `Workspace.${workspace?.id}.showAccessDot`,
    "true"
  );

  const array: SettingTab[] = useMemo(() => {
    const tabs: (SettingTab | false)[] = [
      {
        key: "general",
        icon: <AppstoreOutlined />,
        title: "General",
        component: WorkspaceSettingsGeneral,
      },
      !workspace?.parentId && {
        key: "discord",
        icon: <DiscordIcon />,
        title: (
          <Typography.Text onClick={() => setShowDiscordDot("false")}>
            Discord Integration
            {showDiscordDot === "true" && <MiddleDot color="green" />}
          </Typography.Text>
        ),
        component: WorkspaceDiscordIntegrations,
      },
      !workspace?.parentId && {
        key: "github",
        icon: <GithubOutlined />,
        title: "Github Integration",
        component: WorkspaceGithubIntegrations,
      },
      !workspace?.parentId && {
        key: "notion",
        icon: <NotionIcon />,
        title: "Notion Integration",
        component: WorkspaceNotionIntegrations,
      },
      {
        key: "access",
        icon: <LockOutlined />,
        title: (
          <Typography.Text onClick={() => setShowAccessDot("false")}>
            Permissions
            {showAccessDot === "true" && <MiddleDot color="green" />}
          </Typography.Text>
        ),
        component: WorkspaceRBAC,
      },
      {
        key: "templates",
        icon: <FileOutlined />,
        title: "Templates",
        component: WorkspaceSettingsTaskTemplates,
      },
      {
        key: "payment-method",
        icon: <CreditCardOutlined />,
        title: "Payments",
        component: WorkspaceSettingsPaymentMethod,
      },
      {
        key: "token-gating",
        icon: <SafetyCertificateOutlined />,
        title: "Token Gating",
        component: WorkspaceSettingsTokenGating,
      },
      !!canRemoveWorkspace && {
        key: "manage",
        icon: <SettingOutlined />,
        title: "Manage",
        component: WorkspaceSettingsDangerZone,
      },
    ];

    return tabs.filter((a): a is SettingTab => !!a);
  }, [
    canRemoveWorkspace,
    setShowAccessDot,
    setShowDiscordDot,
    showAccessDot,
    showDiscordDot,
    workspace?.parentId,
  ]);

  return array;
};

export const WorkspaceSettings: FC<Props> = ({ workspace }) => {
  const screens = useBreakpoint();

  const router = useRouter();
  const currentTab =
    (router.query.settingsTab as string | undefined) ?? "general";

  const navigateToSettingsTab = useCallback(
    (tab: string) => router.push(`${workspace!.permalink}/settings/${tab}`),
    [workspace, router]
  );

  const settingsTabs = useWorkspaceSettingsTabs(workspace);

  const isDesktop = screens.md;
  const tabStyle = {
    paddingLeft: isDesktop ? 24 : 0,
    maxWidth: 700,
    marginBottom: 64,
  };
  return (
    <Tabs
      type="card"
      tabPosition={isDesktop ? "left" : "top"}
      className="dewo-settings-tabs"
      activeKey={currentTab}
      onTabClick={navigateToSettingsTab}
      renderTabBar={(props, TabBar) => (
        <Card
          size="small"
          title="Settings"
          style={{ marginBottom: isDesktop ? 0 : 24 }}
          bodyStyle={{ padding: 0 }}
        >
          <TabBar {...props} tabPosition="left" />
        </Card>
      )}
    >
      {settingsTabs.map((settingsTab) => (
        <Tabs.TabPane
          tab={<Tab icon={settingsTab.icon} children={settingsTab.title} />}
          key={settingsTab.key}
          style={tabStyle}
        >
          <Typography.Title level={4} style={{ marginBottom: 4 }}>
            {settingsTab.title}
          </Typography.Title>
          <Divider style={{ marginTop: 0 }} />
          <settingsTab.component workspace={workspace} />
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
};
