import React, { FC, useCallback, useState } from "react";
import { Button, Dropdown, Menu, Tooltip, Typography } from "antd";
import { PaymentTokenForm } from "../../payment/token/PaymentTokenForm";
import {
  PaymentToken,
  WorkspaceDetails,
  WorkspaceRole,
} from "@dewo/app/graphql/types";
import { useCreateWorkspaceTokenGate } from "../hooks";
import { workspaceRoleDescription, workspaceRoleToString } from "./strings";
import { QuestionCircleOutlined } from "@ant-design/icons";

interface Props {
  workspace: WorkspaceDetails;
}

export const CreateWorkspaceTokenGate: FC<Props> = ({ workspace }) => {
  const createWorkspaceTokenGate = useCreateWorkspaceTokenGate();

  const [workspaceRole, setWorkspaceRole] = useState<WorkspaceRole>();

  const handleCreate = useCallback(
    async (token: PaymentToken) => {
      await createWorkspaceTokenGate({
        workspaceId: workspace.id,
        tokenId: token!.id,
        role: workspaceRole!,
      });
    },
    [workspaceRole, workspace, createWorkspaceTokenGate]
  );

  return (
    <>
      <Typography.Paragraph type="secondary">
        Allow users with a certain ERC20, ERC721 or ERC1155 token to join this
        workspace without a manual invite
      </Typography.Paragraph>
      <PaymentTokenForm
        onDone={handleCreate}
        renderSubmitButton={({ onClick, ...buttonProps }) => (
          <Dropdown
            placement="bottom"
            trigger={["click"]}
            overlay={
              <Menu style={{ textAlign: "center" }}>
                <Typography.Text type="secondary">
                  What workspace role should token holders get?
                </Typography.Text>
                {[WorkspaceRole.CONTRIBUTOR, WorkspaceRole.ADMIN].map(
                  (workspaceRole) => (
                    <Menu.Item
                      onClick={() => {
                        setWorkspaceRole(workspaceRole);
                        onClick?.(undefined as any);
                      }}
                      key={workspaceRole}
                    >
                      {workspaceRoleToString[workspaceRole]}
                      <Tooltip
                        placement="right"
                        title={
                          <Typography.Text style={{ whiteSpace: "pre-line" }}>
                            {workspaceRoleDescription[workspaceRole]}
                          </Typography.Text>
                        }
                      >
                        <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                      </Tooltip>
                    </Menu.Item>
                  )
                )}
              </Menu>
            }
          >
            <Button {...buttonProps}>Enable Token Gating</Button>
          </Dropdown>
        )}
      />
    </>
  );
};
