import {
  SearchTasksInput,
  TaskStatus,
  TaskViewField,
  TaskViewSortByDirection,
  TaskViewSortByField,
} from "@dewo/app/graphql/types";
import { Button, Col, Divider, Row, Typography } from "antd";
import React, { FC, useMemo } from "react";
import { TaskTable } from "@dewo/app/containers/task/list/TaskTable";
import { useTaskViewLayoutData } from "@dewo/app/containers/task/views/hooks";
import { TaskViewFieldsProvider } from "@dewo/app/containers/task/views/TaskViewFieldsContext";
import { useWorkspace, useWorkspaceTaskTags } from "../hooks";
import { Can } from "@dewo/app/contexts/PermissionsContext";
import Link from "next/link";
import { PlusOutlined } from "@ant-design/icons";

interface Props {
  workspaceId: string;
}

const fields: TaskViewField[] = [
  TaskViewField.status,
  TaskViewField.name,
  TaskViewField.skills,
  TaskViewField.reward,
  TaskViewField.createdAt,
];

export const MilestoneOverview: FC<Props> = ({ workspaceId }) => {
  const { taskTags } = useWorkspaceTaskTags(workspaceId);
  const milestoneTags = taskTags.filter((t) => t.label === "milestone");
  const [data] = useTaskViewLayoutData(
    useMemo(
      (): SearchTasksInput[] =>
        milestoneTags.length > 0
          ? [
              {
                sortBy: {
                  field: TaskViewSortByField.priority,
                  direction: TaskViewSortByDirection.DESC,
                },
                tagIds: milestoneTags.map((t) => t.id),
                workspaceIds: [workspaceId],
              },
            ]
          : [],
      [milestoneTags, workspaceId]
    )
  );
  const { workspace } = useWorkspace(workspaceId);

  if (!data?.tasks?.length) return null;

  return (
    <div>
      <Row wrap={false}>
        <Col flex={1}>
          <Typography.Title ellipsis level={5}>
            Milestones
          </Typography.Title>
        </Col>
        {!!workspace && !!milestoneTags.length && (
          <Col>
            <Can
              I="create"
              this={{
                __typename: "Task",
                status: TaskStatus.TODO,
                workspaceId,
                // @ts-ignore
                ...{ ownerIds: [] },
              }}
            >
              <Link
                href={`${
                  workspace.permalink
                }/create?values=${encodeURIComponent(
                  JSON.stringify({
                    status: TaskStatus.TODO,
                    tagIds: [milestoneTags[0].id],
                  })
                )}`}
              >
                <Button icon={<PlusOutlined />} size="small">
                  Create
                </Button>
              </Link>
            </Can>
          </Col>
        )}
      </Row>

      <Divider style={{ marginBottom: 0, marginTop: 4 }} />

      <TaskViewFieldsProvider fields={fields}>
        <TaskTable data={data} showHeaders={false} />
      </TaskViewFieldsProvider>
    </div>
  );
};
