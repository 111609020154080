import { EmojiPickerIcon } from "@dewo/app/components/emoji/EmojiPickerIcon";
import { DebouncedInput } from "@dewo/app/components/form/DebouncedInput";
import { HeadlessCollapse } from "@dewo/app/components/HeadlessCollapse";
import { RichMarkdownEditor } from "@dewo/app/components/richMarkdown/RichMarkdownEditor";
import {
  WorkspaceDetails,
  UpdateWorkspaceInput,
  WorkspaceOptionsInput,
  WorkspaceStatus,
  WorkspaceType,
} from "@dewo/app/graphql/types";
import { Space, Form, DatePicker } from "antd";
import { useForm } from "antd/lib/form/Form";
import _ from "lodash";
import moment from "moment";
import React, { FC, useCallback, useMemo } from "react";
import { WorkspaceTypeCheckbox } from "../../create/WorkspaceTypeCheckbox";
import { useUpdateWorkspace } from "../../hooks";
import { WorkspaceSettingsContributorSuggestions } from "./WorkspaceSettingsContributorSuggestions";
import { WorkspaceStatusButton } from "./WorkspaceStatusButton";
import { WorkspaceTaskExports } from "./WorkspaceTaskExports";

interface FormValues {
  name: string;
  icon: string | null;
  options: WorkspaceOptionsInput | null;
  status: WorkspaceStatus;
  type: WorkspaceType;
  description: string;
  dates: [moment.Moment | null, moment.Moment | null] | null;
}

interface Props {
  workspace: WorkspaceDetails;
}

export const WorkspaceSettingsGeneral: FC<Props> = ({ workspace }) => {
  const [form] = useForm<UpdateWorkspaceInput>();
  const updateWorkspace = useUpdateWorkspace();

  const handleUpdateWorkspace = useCallback(
    (changed: Partial<FormValues>) =>
      updateWorkspace({
        id: workspace.id,
        name: changed.name,
        icon: changed.icon,
        type: changed.type,
        status: changed.status,
        options: changed.options,
        description: changed.description,
        startAt:
          changed.dates !== undefined
            ? changed.dates?.[0]?.toISOString() ?? null
            : undefined,
        endAt:
          changed.dates !== undefined
            ? changed.dates?.[1]?.toISOString() ?? null
            : undefined,
      }),
    [workspace.id, updateWorkspace]
  );

  const initialValues = useMemo<FormValues>(
    () => ({
      name: workspace.name,
      icon: workspace.icon,
      type: workspace.type,
      status: workspace.status,
      options: _.omit(workspace.options, "__typename"),
      description: workspace.description || "",
      dates:
        !!workspace.startAt && !!workspace.endAt
          ? [moment(workspace.startAt), moment(workspace.endAt)]
          : null,
    }),
    [workspace]
  );

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      initialValues={initialValues}
      onValuesChange={handleUpdateWorkspace}
    >
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Form.Item label="Name" name="name" style={{ margin: 0 }}>
          <DebouncedInput
            style={{ flex: 1 }}
            prefix={
              <Form.Item name="icon" style={{ margin: 0 }}>
                <EmojiPickerIcon />
              </Form.Item>
            }
          />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          valuePropName="initialValue"
        >
          <RichMarkdownEditor bordered mode="update" editable />
        </Form.Item>

        <WorkspaceSettingsContributorSuggestions />

        <Form.Item name="type" label="Type">
          <WorkspaceTypeCheckbox />
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {(form) => (
            <HeadlessCollapse
              expanded={form.getFieldValue("type") === WorkspaceType.PROJECT}
            >
              <Form.Item
                name="dates"
                label="Dates"
                tooltip="Enable roadmap in Organization Settings to see and plan projects on a timeline"
              >
                <DatePicker.RangePicker />
              </Form.Item>
            </HeadlessCollapse>
          )}
        </Form.Item>

        <Form.Item name="status" hidden={!workspace.parentId}>
          <WorkspaceStatusButton />
        </Form.Item>

        <WorkspaceTaskExports
          workspaceId={workspace.id}
          workspaceName={workspace.name}
        />
      </Space>
    </Form>
  );
};
