import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { TaskStatus, TaskViewFilterType } from "@dewo/app/graphql/types";
import { Button, message, Modal, Typography } from "antd";
import React, { FC, useMemo, useCallback, useEffect, useState } from "react";
import {
  useCreateTaskFromFormValues,
  useTaskDetails,
  useTaskRoles,
} from "./hooks";
import { TaskForm } from "./form/TaskForm";
import { AtLeast } from "@dewo/app/types/general";
import { TaskFormValues } from "./form/types";
import { useRouter } from "next/router";
import { useWorkspace } from "../workspace/hooks";
import moment from "moment";
import { useTaskViewContext } from "./views/TaskViewContext";
import { LocalStorage } from "@dewo/app/util/LocalStorage";
import { toTaskFormValues } from "./form/util";
import { usePrevious } from "@dewo/app/util/hooks";
import { useSubtasks } from "./views/hooks";
import _ from "lodash";
import { CreateTaskAccessDeniedModal } from "@dewo/app/components/modals/CreateTaskAccessDeniedModal";
import { useUnsavedChangesConfirmation } from "./form/useUnsavedChangesConfirmation";

const buildKey = (initialValues: Partial<TaskFormValues>) =>
  `TaskCreateModal.v2.storedValues(${JSON.stringify(
    initialValues,
    Object.keys(initialValues).sort()
  )})`;

interface TaskCreateModalProps {
  visible: boolean;
  workspaceId: string;
  templateId: string | undefined;
  initialValues: AtLeast<TaskFormValues, "status">;
  onCancel(): void;
  onDone(): unknown;
}

export const TaskCreateModal: FC<TaskCreateModalProps> = ({
  workspaceId,
  templateId,
  visible,
  initialValues,
  onCancel,
  onDone,
}) => {
  const { user } = useAuthContext();
  const { currentView } = useTaskViewContext();

  const { task: template } = useTaskDetails(templateId);
  const { tasks: templateSubtasks } = useSubtasks(templateId);
  const { claimRoles: templateClaimRoles, applyRoles: templateApplyRoles } =
    useTaskRoles(template);

  const storedValuesKey = useMemo(
    () => buildKey(initialValues),
    [initialValues]
  );
  const storedValues = useMemo(() => {
    try {
      const storedValuesString = LocalStorage.getItem(storedValuesKey);
      if (!storedValuesString) return undefined;
      return JSON.parse(storedValuesString);
    } catch {
      return undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedValuesKey, visible]);
  const clearStoredValues = useCallback(
    () => LocalStorage.removeItem(storedValuesKey),
    [storedValuesKey]
  );
  const setStoredValues = useCallback(
    (values: Partial<TaskFormValues>) =>
      LocalStorage.setItem(storedValuesKey, JSON.stringify(values)),
    [storedValuesKey]
  );

  const prevTemplateId = usePrevious(templateId);
  useEffect(() => {
    if (!!prevTemplateId && !templateId) {
      clearStoredValues();
    }
  }, [templateId, prevTemplateId, clearStoredValues]);

  const canCreateTaskOwner = usePermission("create", {
    __typename: "Task",
    workspaceId,
    status: initialValues.status,
    owners: !!user ? [user] : [],
  });

  const taskGatingDefault = useMemo(
    () => user?.taskGatingDefaults.find((d) => d.workspaceId === workspaceId),
    [workspaceId, user?.taskGatingDefaults]
  );

  const viewValues = useMemo(() => {
    const filter = (type: TaskViewFilterType) =>
      currentView?.filters.find((filter) => filter.type === type);

    const priority = filter(TaskViewFilterType.PRIORITIES)?.priorities?.[0];
    const tagIds = filter(TaskViewFilterType.TAGS)?.tagIds;
    const assigneeIds = filter(
      TaskViewFilterType.ASSIGNEES
    )?.assigneeIds?.filter((id): id is string => !!id);
    const claimRoleIds =
      filter(TaskViewFilterType.ROLES)?.roleIds ??
      taskGatingDefault?.claimRoles.map((r) => r.id);
    const applyRoleIds = taskGatingDefault?.applyRoles.map((r) => r.id);
    const skillIds = filter(TaskViewFilterType.SKILLS)?.skillIds;
    const ownerIds = (() => {
      if (!canCreateTaskOwner) return [];
      const ids = filter(TaskViewFilterType.OWNERS)?.ownerIds;
      if (!!ids) return ids;
      if (!!user) return [user.id];
      return [];
    })();

    return {
      tagIds,
      priority,
      ...(assigneeIds ? { assigneeIds } : {}),
      ...(claimRoleIds ? { claimRoleIds } : {}),
      ...(applyRoleIds ? { applyRoleIds } : {}),
      ownerIds,
      skillIds,
    };
  }, [
    taskGatingDefault?.claimRoles,
    taskGatingDefault?.applyRoles,
    currentView?.filters,
    canCreateTaskOwner,
    user,
  ]);

  const templateValues = useMemo<Partial<TaskFormValues>>(() => {
    if (
      !template ||
      !templateClaimRoles ||
      !templateApplyRoles ||
      !templateSubtasks
    ) {
      return {};
    }
    const values = toTaskFormValues(
      template,
      templateClaimRoles,
      templateApplyRoles,
      templateSubtasks
    );
    values.template = false;
    if (!canCreateTaskOwner) return _.omit(values, "ownerIds");
    return values;
  }, [
    template,
    templateClaimRoles,
    templateApplyRoles,
    templateSubtasks,
    canCreateTaskOwner,
  ]);

  const extendedInitialValues = useMemo<Partial<TaskFormValues>>(() => {
    return {
      workspaceId,
      openToBids: false,
      rewards: [],
      gating: taskGatingDefault?.type,
      ...storedValues,
      templateTaskId: templateId,
      dueDate: !!storedValues?.dueDate
        ? moment(storedValues.dueDate)
        : undefined,
      ...viewValues,
      ...initialValues,
      ...templateValues,
    };
  }, [
    workspaceId,
    taskGatingDefault?.type,
    storedValues,
    templateId,
    viewValues,
    initialValues,
    templateValues,
  ]);

  const createTask = useCreateTaskFromFormValues();
  const { workspace } = useWorkspace(workspaceId);

  const status = extendedInitialValues.status;
  const prevStatus = usePrevious(status);
  const isSuggestion =
    (status ?? prevStatus) === TaskStatus.COMMUNITY_SUGGESTIONS;

  const router = useRouter();
  const handleSubmit = useCallback(
    async (values: TaskFormValues) => {
      onDone();
      clearStoredValues();
      try {
        const task = await createTask(values, workspaceId);
        message.success({
          duration: 5,
          content: (
            <>
              <Typography.Text style={{ marginRight: 16 }}>
                {values.template ? "Template created" : "Task created"}
              </Typography.Text>
              <Button
                size="small"
                onClick={() =>
                  router.push(`${workspace?.permalink}?taskId=${task.id}`)
                }
              >
                View
              </Button>
            </>
          ),
        });
      } catch (e) {
        setStoredValues(values);
        message.error({
          duration: 5,
          content: (
            <Typography.Text>
              {values.template
                ? "Template creation failed. Please try again"
                : "Task creation failed. Please try again"}
            </Typography.Text>
          ),
        });
      }
    },
    [
      onDone,
      clearStoredValues,
      createTask,
      workspaceId,
      router,
      workspace?.permalink,
      setStoredValues,
    ]
  );

  const canCreate = usePermission("create", {
    __typename: "Task",
    ...(extendedInitialValues as any),
  });

  const [dirty, setDirty] = useState(false);
  const handleChange = useCallback(
    (values: Partial<TaskFormValues>) => {
      setDirty(!_.isEqual(values, extendedInitialValues));
      setStoredValues(values);
    },
    [extendedInitialValues, setStoredValues]
  );
  const handleConfirmUnsavedChanges = useUnsavedChangesConfirmation(
    dirty,
    onCancel
  );
  useEffect(() => {
    if (visible && canCreate) {
      setDirty(false);
    }
  }, [visible, canCreate]);

  return (
    <>
      <Modal
        visible={visible && canCreate}
        destroyOnClose
        maskClosable={false}
        onCancel={handleConfirmUnsavedChanges}
        footer={null}
        width={isSuggestion ? 720 : 1000}
      >
        <TaskForm
          key={template?.id}
          mode="create"
          workspaceId={workspaceId}
          initialValues={extendedInitialValues}
          onChange={handleChange}
          onSubmit={handleSubmit}
        />
      </Modal>
      <CreateTaskAccessDeniedModal visible={visible && !canCreate} />
    </>
  );
};
