import {
  WorkspaceRole,
  RulePermission,
  WorkspaceDetails,
  Workspace,
} from "@dewo/app/graphql/types";
import { Card, Divider, Spin, Tag, Tooltip, Typography } from "antd";
import React, { FC, useCallback, useMemo } from "react";
import { workspaceRoleDescription } from "../../workspace/settings/strings";
import { useOrganizationRoles } from "../hooks";
import { RBACPermissionForm } from "../RBACPermissionForm";
import { WorkspacePrivatePermissionForm } from "./WorkspacePrivatePermissionForm";
import { useCopyToClipboardAndShowToast } from "@dewo/app/util/hooks";
import { useCreateInvite } from "../../invite/hooks";
import styles from "../CardRBAC.module.less";
import { RuleContext } from "../util";
import { useOrganizationDetails } from "../../organization/hooks";
import _ from "lodash";
import { QuestionCircleOutlined } from "@ant-design/icons";

interface Props {
  workspace: WorkspaceDetails;
}

function useParentWorkspaces(workspace: Workspace): Workspace[] | undefined {
  const { organization } = useOrganizationDetails(workspace.organizationId);
  return useMemo(() => {
    if (!organization) return undefined;

    const parents: Workspace[] = [workspace];
    const workspaceById = _.keyBy(organization.workspaces, "id");
    for (let i = 0; i < parents.length; i++) {
      if (parents[i].parentId) {
        const parent = workspaceById[parents[i].parentId!];
        if (!!parent) parents.push(parent);
      }
    }

    return parents.slice(1);
  }, [organization, workspace]);
}

export const WorkspaceRBAC: FC<Props> = ({ workspace }) => {
  const roles = useOrganizationRoles(workspace.organizationId);

  const copyToClipboardAndShowToast =
    useCopyToClipboardAndShowToast("Invite link copied");
  const createInvite = useCreateInvite();
  const inviteToWorkspace = useCallback(
    async (permission: RulePermission) => {
      const inviteLink = await createInvite({
        permission,
        workspaceId: workspace.id,
      });
      copyToClipboardAndShowToast(inviteLink);
    },
    [createInvite, copyToClipboardAndShowToast, workspace.id]
  );

  const ruleContext = useMemo<RuleContext>(
    () => ({ workspaceId: workspace.id }),
    [workspace.id]
  );

  const parentWorkspaces = useParentWorkspaces(workspace);
  const parentRuleContexts = useMemo<RuleContext[] | undefined>(
    () => parentWorkspaces?.map((workspace) => ({ workspaceId: workspace.id })),
    [parentWorkspaces]
  );

  if (!roles) return <Spin />;
  return (
    <>
      <Typography.Title level={5}>
        Manage Space
        <Tooltip
          title={
            <Typography.Text style={{ whiteSpace: "pre-line" }}>
              {workspaceRoleDescription[WorkspaceRole.ADMIN]}
            </Typography.Text>
          }
        >
          <QuestionCircleOutlined style={{ marginLeft: 8 }} />
        </Tooltip>
      </Typography.Title>
      <Card className={`bg-body-secondary ${styles.card}`}>
        <RBACPermissionForm
          permission={RulePermission.MANAGE_PROJECTS}
          roles={roles}
          context={ruleContext}
          parentContexts={parentRuleContexts}
          organizationId={workspace.organizationId}
          onInviteUser={() => inviteToWorkspace(RulePermission.MANAGE_PROJECTS)}
        />
      </Card>
      <Divider />
      <Typography.Title level={5}>
        Create Tasks
        <Tag
          color="green"
          style={{
            marginLeft: 4,
            fontWeight: "normal",
            textTransform: "none",
          }}
        >
          Recommended!
        </Tag>
        <Tooltip
          title={
            <Typography.Text style={{ whiteSpace: "pre-line" }}>
              This gives users the permission to create their own tasks and
              manage those (but not to attach a bounty to them). Highly
              recommended to give to all somewhat active contributors in your
              community
            </Typography.Text>
          }
        >
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </Typography.Title>
      <Card className={`bg-body-secondary ${styles.card}`}>
        <RBACPermissionForm
          permission={RulePermission.MANAGE_TASKS}
          roles={roles}
          context={ruleContext}
          parentContexts={parentRuleContexts}
          organizationId={workspace.organizationId}
          onInviteUser={() => inviteToWorkspace(RulePermission.MANAGE_TASKS)}
        />
      </Card>
      <Divider />

      <Typography.Title level={5}>
        View Space
        <Tooltip
          title={
            <Typography.Text style={{ whiteSpace: "pre-line" }}>
              {workspaceRoleDescription[WorkspaceRole.CONTRIBUTOR]}
            </Typography.Text>
          }
        >
          <QuestionCircleOutlined style={{ marginLeft: 8 }} />
        </Tooltip>
      </Typography.Title>
      <Card className={`bg-body-secondary ${styles.card}`}>
        <WorkspacePrivatePermissionForm
          workspace={workspace}
          onInviteUser={() => inviteToWorkspace(RulePermission.VIEW_PROJECTS)}
        />
      </Card>
    </>
  );
};
