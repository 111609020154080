import React, { FC, useMemo } from "react";
import {
  OrganizationIntegrationType,
  WorkspaceDetails,
  WorkspaceIntegrationFeature,
  WorkspaceIntegrationType,
} from "@dewo/app/graphql/types";
import { Card, Skeleton, Space, Typography } from "antd";
import { useOrganizationIntegrations } from "@dewo/app/containers/organization/hooks";
import { ConnectOrganizationToGithubButton } from "@dewo/app/containers/integrations/github/ConnectOrganizationToGithubButton";
import { CreateGithubIntegrationFeatureForm } from "@dewo/app/containers/integrations/github/CreateGithubIntegrationFeatureForm";
import { useWorkspaceIntegrations } from "../../hooks";

interface Props {
  workspace: WorkspaceDetails;
}

export const WorkspaceGithubIntegrations: FC<Props> = ({ workspace }) => {
  const hasGithubOrganizationIntegration = !!useOrganizationIntegrations(
    workspace.organizationId,
    OrganizationIntegrationType.GITHUB
  )?.length;

  const allWorkspaceIntegrations = useWorkspaceIntegrations(workspace.id);
  const githubWorkspaceIntegrations = useMemo(
    () =>
      allWorkspaceIntegrations?.filter(
        (i) => i.type === WorkspaceIntegrationType.GITHUB
      ),
    [allWorkspaceIntegrations]
  );

  const copy = "Connect one or more repos to your Dework workspace";

  return (
    <>
      <Skeleton loading={!githubWorkspaceIntegrations}>
        <Space size="middle" direction="vertical" style={{ width: "100%" }}>
          {hasGithubOrganizationIntegration ? (
            <Typography.Text type="secondary">{copy}</Typography.Text>
          ) : (
            <Card
              className="dewo-card-highlighted"
              bodyStyle={{
                display: "flex",
                alignItems: "center",
                padding: 12,
              }}
            >
              <ConnectOrganizationToGithubButton
                organizationId={workspace.organizationId}
                type="primary"
                icon={null}
                style={{ marginTop: 0 }}
              >
                Connect Github
              </ConnectOrganizationToGithubButton>
              <Typography.Paragraph style={{ margin: 0, marginLeft: 12 }}>
                {copy}
              </Typography.Paragraph>
            </Card>
          )}
          {[
            WorkspaceIntegrationFeature.GITHUB_SHOW_BRANCHES_AND_PULL_REQUESTS,
            WorkspaceIntegrationFeature.GITHUB_CREATE_TASKS_FROM_ISSUES,
          ].map((feature) => (
            <CreateGithubIntegrationFeatureForm
              key={feature}
              feature={feature}
              workspaceId={workspace.id}
              existingIntegrations={
                githubWorkspaceIntegrations?.filter(
                  (i) => i.feature === feature
                ) ?? []
              }
              disabled={!hasGithubOrganizationIntegration}
            />
          ))}
        </Space>
      </Skeleton>
    </>
  );
};
