import React, { FC, useCallback, useState } from "react";
import { Button, Card, Form, Input, Typography } from "antd";
import { WorkspaceDetails } from "@dewo/app/graphql/types";
import { useDeleteWorkspace } from "../hooks";
import { useRouter } from "next/router";
import { useRunning, useToggle } from "@dewo/app/util/hooks";
import { FormSection } from "@dewo/app/components/form/FormSection";

interface Props {
  workspace: WorkspaceDetails;
}

export const WorkspaceSettingsDangerZone: FC<Props> = ({ workspace }) => {
  const router = useRouter();
  const deleteWorkspace = useDeleteWorkspace();
  const [handleDelete, deleting] = useRunning(
    useCallback(async () => {
      const organization = await deleteWorkspace(workspace.id);
      router.push({ pathname: organization.permalink });
    }, [deleteWorkspace, router, workspace.id])
  );

  const deletingWorkspace = useToggle(false);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const handleDeleteConfirmation = (e: React.FormEvent<HTMLInputElement>) => {
    setConfirmDelete(e.currentTarget.value === workspace?.name!);
  };

  return (
    <FormSection label="DANGER ZONE">
      <Card
        className="dewo-danger-zone bg-body-secondary"
        size="small"
        bodyStyle={{ padding: 10 }}
      >
        <Form.Item name="delete" style={{ margin: 0 }}>
          <Button onClick={deletingWorkspace.toggle}>Delete Space</Button>

          {deletingWorkspace.isOn && (
            <>
              <Typography.Paragraph
                type="secondary"
                style={{ marginBottom: 8, marginTop: 8 }}
              >
                Enter the Space name to delete the workspace. This action is not
                reversible.
              </Typography.Paragraph>
              <Input
                placeholder="Type Space name to confirm"
                onChange={handleDeleteConfirmation}
                style={{ marginBottom: 16 }}
              />
              <Button
                danger
                loading={deleting}
                disabled={!confirmDelete}
                onClick={handleDelete}
              >
                I understand the consequences, delete this Space
              </Button>
            </>
          )}
        </Form.Item>
      </Card>
    </FormSection>
  );
};
