import {
  BellOutlined,
  DownOutlined,
  PlusOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { Can } from "@dewo/app/contexts/PermissionsContext";
import { WorkspaceDetails } from "@dewo/app/graphql/types";
import { useToggle } from "@dewo/app/util/hooks";
import { Button, Modal, Popover, Row, Space, Typography } from "antd";
import React, { FC, useCallback } from "react";
import { CreateThreadMessageForm } from "../../thread/CreateThreadMessageForm";
import { ThreadMessageList } from "../../thread/ThreadMessageList";
import { useUpdateWorkspace } from "../hooks";
import { WorkspaceName } from "../WorkspaceName";
import { WorkspaceActivityFeedEmpty } from "./WorkspaceActivityFeedEmpty";
import * as qs from "query-string";
import { useAmplitude } from "@dewo/app/util/analytics/AmplitudeContext";
import { useCreateThread } from "../../thread/hooks";
import { ActivityFeedReminderForm } from "./ActivityFeedReminderForm";

interface Props {
  workspace: WorkspaceDetails;
}

export const WorkspaceActivityFeed: FC<Props> = ({ workspace }) => {
  const thread = workspace.activityThread;

  const { logEvent } = useAmplitude();

  const modal = useToggle();
  const showCreateModal = useCallback(() => {
    logEvent("Show workspace activity feed create form", {
      workspaceId: workspace.id,
      organizationId: workspace.organizationId,
    });
    modal.toggleOn();
  }, [modal, logEvent, workspace.id, workspace.organizationId]);

  const shareOnTwitter = useCallback(() => {
    const text = `⭐️ New update in ${workspace.name} ⭐️\n\nCheck out the details 👇\n${workspace.permalink}`;

    window.open(
      `https://twitter.com/intent/tweet?${qs.stringify({
        text,
        hashtags: "dework",
      })}`,
      "_blank"
    );
  }, [workspace.name, workspace.permalink]);

  const createThread = useCreateThread();
  const updateWorkspace = useUpdateWorkspace();
  const createThreadIfNotExists = useCallback(async () => {
    if (!!workspace.activityThread) {
      return { threadId: workspace.activityThread.id };
    }

    const thread = await createThread();
    await updateWorkspace({
      id: workspace.id,
      activityThreadId: thread.id,
    });

    return { threadId: thread.id };
  }, [createThread, updateWorkspace, workspace]);

  return (
    <>
      <Row align="middle" justify="space-between" style={{ marginBottom: 8 }}>
        <Typography.Title level={5} style={{ margin: 0 }}>
          Activity Feed
        </Typography.Title>
        <Can I="update" this={workspace}>
          <Space>
            <Popover
              overlayClassName="max-w-xs w-full"
              trigger="click"
              placement="bottomLeft"
              content={<ActivityFeedReminderForm workspace={workspace} />}
            >
              <Button size="small" icon={<BellOutlined />}>
                <DownOutlined />
              </Button>
            </Popover>
            <Button
              size="small"
              icon={<PlusOutlined />}
              onClick={showCreateModal}
            >
              New
            </Button>
          </Space>
        </Can>
      </Row>
      {!!thread && (
        <ThreadMessageList
          thread={thread}
          renderMessageHeader={() => <WorkspaceName workspace={workspace} />}
          renderExtraMenuItems={() => [
            {
              key: "twitter",
              label: "Share",
              icon: <TwitterOutlined />,
              onClick: shareOnTwitter,
            },
          ]}
        />
      )}
      {!thread?.messages.length && (
        <WorkspaceActivityFeedEmpty onClick={showCreateModal} />
      )}
      <Modal
        visible={modal.isOn}
        onCancel={modal.toggleOff}
        footer={null}
        title="Add Update"
      >
        <CreateThreadMessageForm
          key={thread?.messages.length}
          threadId={thread?.id}
          buttonText="Create"
          onBeforeSend={createThreadIfNotExists}
          onDone={modal.toggleOff}
        />
      </Modal>
    </>
  );
};
