import { PlusCircleOutlined } from "@ant-design/icons";
import { Card, CardProps, Typography } from "antd";
import React, { FC } from "react";
import styles from "./WorkspaceActivityFeedEmpty.module.less";

export const WorkspaceActivityFeedEmpty: FC<CardProps> = ({
  onClick,
  children = "Post your first status update!",
  ...cardProps
}) => (
  <Card className={styles.empty} onClick={onClick} {...cardProps}>
    <PlusCircleOutlined
      style={{ opacity: 0.2, fontSize: 36, marginBottom: 8 }}
    />
    <Typography.Paragraph type="secondary" style={{ margin: 0 }}>
      {children}
    </Typography.Paragraph>
  </Card>
);
