import { Row, Switch, Typography } from "antd";
import React, { FC, useCallback, useMemo } from "react";
import {
  useCreateRole,
  useCreateRule,
  useDeleteRule,
  useIsWorkspacePrivate,
  useOrganizationRoles,
} from "../hooks";
import { RulePermission, WorkspaceDetails } from "@dewo/app/graphql/types";
import { RBACPermissionForm } from "../RBACPermissionForm";
import {
  useDefaultAbility,
  usePermission,
} from "@dewo/app/contexts/PermissionsContext";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { RuleContext } from "../util";

interface Props {
  workspace: WorkspaceDetails;
  onInviteUser?(): Promise<void>;
}

export const WorkspacePrivatePermissionForm: FC<Props> = ({
  workspace,
  onInviteUser,
}) => {
  const { user } = useAuthContext();
  const roles = useOrganizationRoles(workspace.organizationId);
  const canManagePermissions = usePermission("create", {
    __typename: "Rule",
    permission: RulePermission.VIEW_PROJECTS,
    workspaceId: workspace.id,
  });

  const refetchDefaultAbility = useDefaultAbility(
    workspace.organizationId
  ).refetch;

  const isParentPrivate = useIsWorkspacePrivate(
    workspace.parent ?? undefined,
    workspace.organizationId
  );

  const fallbackRole = useMemo(() => roles?.find((r) => r.fallback), [roles]);
  const privateRule = useMemo(
    () =>
      fallbackRole?.rules.find(
        (r) =>
          r.workspaceId === workspace.id &&
          r.inverted &&
          r.permission === RulePermission.VIEW_PROJECTS
      ),
    [fallbackRole, workspace.id]
  );

  const ruleContext = useMemo<RuleContext>(
    () => ({ workspaceId: workspace.id }),
    [workspace.id]
  );

  const createRole = useCreateRole();
  const createRule = useCreateRule();
  const deleteRule = useDeleteRule();

  const handleChangePrivate = useCallback(
    async (toggled: boolean) => {
      if (toggled) {
        const personalRole =
          roles?.find((r) => r.userId === user!.id) ??
          (await createRole({
            name: "",
            color: "",
            // Note(fant): in the future we can move this to be a user role in the workspace, rather than the org
            parentId: workspace.organization.nodeId,
            userId: user!.id,
            userIds: [user!.id],
          }));
        await createRule({
          permission: RulePermission.VIEW_PROJECTS,
          workspaceId: workspace.id,
          roleId: personalRole.id,
        });

        await createRule({
          permission: RulePermission.VIEW_PROJECTS,
          workspaceId: workspace.id,
          inverted: true,
          roleId: fallbackRole!.id,
        });
      } else {
        if (!!privateRule) {
          await deleteRule(privateRule.id);
        }
      }

      await refetchDefaultAbility();
    },
    [
      deleteRule,
      refetchDefaultAbility,
      privateRule,
      roles,
      workspace.id,
      workspace.organization.nodeId,
      createRule,
      createRole,
      fallbackRole,
      user,
    ]
  );

  if (!!workspace.parent && isParentPrivate) {
    return (
      <>
        <Typography.Paragraph type="secondary">
          This workspace is private because its parent "{workspace.parent.name}"
          is private
        </Typography.Paragraph>
        <Row align="middle" style={{ gap: 8, marginBottom: 8 }}>
          <Typography.Text>Private Space</Typography.Text>
          <Switch checked disabled />
        </Row>
      </>
    );
  }

  return (
    <>
      <Typography.Paragraph type="secondary">
        {!!privateRule
          ? "Only the below roles and users can view this workspace and its tasks"
          : "Anyone can view this workspace and its tasks"}
      </Typography.Paragraph>
      <Row align="middle" style={{ gap: 8, marginBottom: 8 }}>
        <Typography.Text>Private Space</Typography.Text>
        <Switch
          checked={!!privateRule}
          onChange={handleChangePrivate}
          disabled={!canManagePermissions}
        />
      </Row>
      {!!privateRule && !!roles && (
        <RBACPermissionForm
          permission={RulePermission.VIEW_PROJECTS}
          roles={roles}
          disallowEveryoneElse
          context={ruleContext}
          organizationId={workspace.organizationId}
          saveButtonTooltip="To make a workspace private, you need to select at least one role you have. Otherwise you will no longer be able to access the workspace."
          requiresCurrentUserToHaveRole
          onInviteUser={onInviteUser}
        />
      )}
    </>
  );
};
