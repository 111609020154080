import { Thread, ThreadMessage } from "@dewo/app/graphql/types";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import React, { FC, ReactNode } from "react";
import { ThreadMessageListItem } from "./ThreadMessageListItem";

interface Props {
  thread: Thread;
  renderMessageHeader?(message: ThreadMessage): ReactNode;
  renderExtraMenuItems?(message: ThreadMessage): ItemType[];
}

export const ThreadMessageList: FC<Props> = ({
  thread,
  renderMessageHeader,
  renderExtraMenuItems,
}) => {
  return (
    <>
      {thread.messages.map((message) => (
        <ThreadMessageListItem
          key={message.id}
          thread={thread}
          message={message}
          header={renderMessageHeader?.(message)}
          extraMenuItems={renderExtraMenuItems?.(message)}
        />
      ))}
    </>
  );
};
