import React, { useCallback } from "react";
import { Form, Select, Typography } from "antd";
import { NotionDatabase } from "@dewo/app/graphql/types";
import { useConnectToNotion } from "./hooks";

interface SelectGihubRepoFormItemProps {
  organizationId: string;
  databases: NotionDatabase[] | undefined;
}

export const SelectNotionDatabaseFormItem = ({
  organizationId,
  databases,
}: SelectGihubRepoFormItemProps) => {
  const connectToNotion = useConnectToNotion();
  const handleAdd = useCallback(
    () => connectToNotion(organizationId),
    [connectToNotion, organizationId]
  );
  return (
    <>
      {databases ? (
        <Typography.Paragraph type="secondary">
          Can't find your Notion database?{" "}
          <a target="_blank" rel="noreferrer" onClick={handleAdd}>
            Add it
          </a>{" "}
          to your installation configuration.
        </Typography.Paragraph>
      ) : (
        <Typography.Paragraph type="secondary">
          Link a Notion database to automatically sync Dework bounties
        </Typography.Paragraph>
      )}
      <Form.Item
        name="databaseIds"
        style={{ marginBottom: 0 }}
        rules={[{ required: true, message: "Please select a Notion database" }]}
      >
        <Select
          mode="multiple"
          loading={!databases}
          placeholder="Select Notion Database..."
          allowClear
          showSearch
          optionFilterProp="label"
        >
          {databases?.map((database) => (
            <Select.Option
              key={database.id}
              value={database.id}
              label={database.name}
            >
              {database.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};
