import { Button, Dropdown, Menu, Tag, Tooltip, Typography } from "antd";
import React, {
  CSSProperties,
  FC,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useCopyToClipboardAndShowToast } from "@dewo/app/util/hooks";
import { useWorkspace } from "../workspace/hooks";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import {
  WorkspaceRole,
  RoleSource,
  RulePermission,
} from "@dewo/app/graphql/types";
import { workspaceRoleDescription } from "../workspace/settings/strings";
import Link from "next/link";
import { useOrganizationRoles } from "../rbac/hooks";
import { useCreateInvite } from "./hooks";
import {
  QuestionCircleOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

interface Props {
  workspaceId: string;
  style?: CSSProperties;
}

export const WorkspaceInviteButton: FC<Props> = ({ workspaceId, style }) => {
  const [loading, setLoading] = useState(false);
  const { workspace } = useWorkspace(workspaceId);

  const canInvite = usePermission("create", {
    __typename: "Rule",
    workspaceId,
    permission: RulePermission.MANAGE_PROJECTS,
  });

  const copyToClipboardAndShowToast =
    useCopyToClipboardAndShowToast("Invite link copied");
  const createInvite = useCreateInvite();
  const inviteToWorkspace = useCallback(
    async (permission: RulePermission) => {
      try {
        setLoading(true);
        const inviteLink = await createInvite({
          permission,
          workspaceId: workspace!.id,
        });
        copyToClipboardAndShowToast(inviteLink);
      } finally {
        setLoading(false);
      }
    },
    [createInvite, copyToClipboardAndShowToast, workspace]
  );
  const inviteWorkspaceContributor = useCallback(
    () => inviteToWorkspace(RulePermission.VIEW_PROJECTS),
    [inviteToWorkspace]
  );
  const inviteWorkspaceAdmin = useCallback(
    () => inviteToWorkspace(RulePermission.MANAGE_PROJECTS),
    [inviteToWorkspace]
  );

  const roles = useOrganizationRoles(workspace?.organizationId);
  const discordRoleGatingEnabled = useMemo(
    () =>
      !!roles?.some(
        (role) =>
          role.source === RoleSource.DISCORD &&
          role.rules.some((rule) => rule.workspaceId === workspaceId)
      ),
    [roles, workspaceId]
  );

  if (!workspace) return null;
  if (!canInvite) return null;
  return (
    <Dropdown
      placement="bottom"
      trigger={["click"]}
      overlay={
        <Menu>
          <Menu.Item onClick={inviteWorkspaceContributor}>
            Invite to View Space
            <Tooltip
              placement="right"
              title={
                <Typography.Text style={{ whiteSpace: "pre-line" }}>
                  {workspaceRoleDescription[WorkspaceRole.CONTRIBUTOR]}
                </Typography.Text>
              }
            >
              <QuestionCircleOutlined style={{ marginLeft: 8 }} />
            </Tooltip>
          </Menu.Item>
          <Menu.Item onClick={inviteWorkspaceAdmin}>
            Invite to Manage Space
            <Tooltip
              placement="right"
              title={
                <Typography.Text style={{ whiteSpace: "pre-line" }}>
                  {workspaceRoleDescription[WorkspaceRole.ADMIN]}
                </Typography.Text>
              }
            >
              <QuestionCircleOutlined style={{ marginLeft: 8 }} />
            </Tooltip>
          </Menu.Item>
          {discordRoleGatingEnabled ? (
            <Menu.Item
              onClick={() => copyToClipboardAndShowToast(workspace.permalink)}
            >
              Invite with Discord role
              <Tooltip
                placement="right"
                title={
                  <Typography.Text style={{ whiteSpace: "pre-line" }}>
                    Let users join this workspace using their Discord server
                    roles
                  </Typography.Text>
                }
              >
                <QuestionCircleOutlined style={{ marginLeft: 8 }} />
              </Tooltip>
            </Menu.Item>
          ) : (
            <Link href={`${workspace.permalink}/settings/access`}>
              <a>
                <Menu.Item>
                  Setup Discord Role Gating
                  <Tag color="green" style={{ margin: 0, marginLeft: 4 }}>
                    New
                  </Tag>
                  <Tooltip
                    placement="right"
                    title={
                      <Typography.Text style={{ whiteSpace: "pre-line" }}>
                        Allow your community join this workspace as workspace
                        contributor or steward, depending on their Discord role.
                      </Typography.Text>
                    }
                  >
                    <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                  </Tooltip>
                </Menu.Item>
              </a>
            </Link>
          )}
        </Menu>
      }
    >
      <Button loading={loading} icon={<UsergroupAddOutlined />} style={style}>
        Invite
      </Button>
    </Dropdown>
  );
};
