import {
  OrganizationIntegrationType,
  Workspace,
  WorkspaceIntegrationFeature,
} from "@dewo/app/graphql/types";
import { Button, Form, Row, Typography } from "antd";
import React, { FC, useCallback, useEffect } from "react";
import { useWorkspaceIntegrations } from "../hooks";
import { ConnectOrganizationToDiscordButton } from "../../integrations/discord/ConnectOrganizationToDiscordButton";
import {
  useOrganizationDiscordChannels,
  useOrganizationIntegrations,
} from "../../organization/hooks";
import {
  useCreateDiscordWorkspaceIntegration,
  useUpdateWorkspaceIntegration,
} from "../../integrations/hooks";
import { SelectDiscordChannelFormItem } from "../../integrations/discord/SelectDiscordChannelFormItem";
import { weekdays } from "moment";
import { useRunning } from "@dewo/app/util/hooks";

interface FormValues {
  period: "week" | "month";
  day: number | undefined;
  sendReminder: boolean;
  discordChannelId: string | undefined;
}
export const ActivityFeedReminderForm: FC<{ workspace: Workspace }> = ({
  workspace,
}) => {
  const [form] = Form.useForm<FormValues>();
  const isDiscordConnected = !!useOrganizationIntegrations(
    workspace.organizationId,
    OrganizationIntegrationType.DISCORD
  )?.length;
  const existingIntegration = useWorkspaceIntegrations(workspace.id)?.find(
    (i) => i.feature === WorkspaceIntegrationFeature.DISCORD_POST_ACTIVITY_FEED
  );

  const { value: channels } = useOrganizationDiscordChannels({
    organizationId: workspace.organizationId,
  });

  const createIntegration = useCreateDiscordWorkspaceIntegration();
  const updateIntegration = useUpdateWorkspaceIntegration();

  const handleDisconnect = useCallback(async () => {
    try {
      await updateIntegration({
        id: existingIntegration?.id!,
        deletedAt: new Date().toISOString(),
      });
    } finally {
      form.resetFields();
    }
  }, [existingIntegration?.id, form, updateIntegration]);

  const [handleSubmit, isSubmitting] = useRunning(
    useCallback(
      async (values: FormValues) => {
        const channel = channels?.find((c) => c.id === values.discordChannelId);
        if (!channel) return;

        const getFrequency = () => {
          if (!values.sendReminder) return undefined;
          // Convert into cron expression
          if (values.period === "week") {
            return `0 0 * * ${values.day ?? 1}`;
          }
          if (values.period === "month") {
            return `0 0 ${values.day ?? 1} * *`;
          }
        };

        const frequency = getFrequency();
        const update = {
          channel,
          feature: WorkspaceIntegrationFeature.DISCORD_POST_ACTIVITY_FEED,
          config: {
            frequency,
          },
        };
        await createIntegration({
          workspaceId: workspace.id,
          ...update,
        });
      },
      [channels, createIntegration, workspace.id]
    )
  );

  const period = Form.useWatch("period", form);
  const discordChannelId = Form.useWatch("discordChannelId", form);
  const day = Form.useWatch("day", form);
  const sendReminder = Form.useWatch("sendReminder", form);

  useEffect(() => {
    if (period) form.setFieldsValue({ day: undefined });
  }, [period, form]);

  if (!isDiscordConnected) {
    return (
      <ConnectOrganizationToDiscordButton
        type="primary"
        organizationId={workspace.organizationId}
      />
    );
  }

  if (existingIntegration) {
    const dayOfWeek = existingIntegration.config.frequency?.split(" ").pop();
    const dayOfMonth = existingIntegration.config.frequency?.split(" ")[2];
    const getDate = (day: number) =>
      day + (["th", "st", "nd", "rd"][day % 10] || "th");

    return (
      <>
        <Typography.Paragraph className="font-semibold">
          <Typography.Text type="secondary">Connected to</Typography.Text>
          <Typography.Text style={{ marginLeft: 4 }}>
            {`#${existingIntegration?.config.name}`}
          </Typography.Text>
        </Typography.Paragraph>
        {!isNaN(dayOfWeek) && (
          <Typography.Paragraph>
            You will get a reminder every {weekdays()[dayOfWeek]}.
          </Typography.Paragraph>
        )}
        {!isNaN(dayOfMonth) && (
          <Typography.Paragraph>
            You will get a reminder every month on the {getDate(dayOfMonth)}.
          </Typography.Paragraph>
        )}
        <Row justify="end">
          <Button onClick={handleDisconnect}>Disconnect</Button>
        </Row>
      </>
    );
  }

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{ period: "month" }}
      onFinish={handleSubmit}
    >
      <SelectDiscordChannelFormItem
        organizationId={workspace.organizationId}
        missingPermissions={[]}
        feature={WorkspaceIntegrationFeature.DISCORD_POST_ACTIVITY_FEED}
      />
      <Row justify="end">
        <Button
          disabled={!discordChannelId || (sendReminder && day == null)}
          loading={isSubmitting}
          type="primary"
          htmlType="submit"
        >
          Save
        </Button>
      </Row>
    </Form>
  );
};
