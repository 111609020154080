import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import * as Colors from "@ant-design/colors";
import { Can } from "@dewo/app/contexts/PermissionsContext";
import { WorkspaceDetails, Task, TaskStatus } from "@dewo/app/graphql/types";
import {
  Button,
  Card,
  Divider,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Tooltip,
  Typography,
} from "antd";
import Link from "next/link";
import React, { FC, useCallback, useMemo } from "react";
import { useNavigateToTask } from "@dewo/app/util/navigation";
import { useWorkspaceTaskTemplates } from "../hooks";
import { useDeleteTask } from "../../task/hooks";
import { useRunning } from "@dewo/app/util/hooks";
import _ from "lodash";
import { TaskFormValues } from "../../task/form/types";

interface Props {
  workspace: WorkspaceDetails;
}

interface ListItemProps {
  task: Task;
  workspace: WorkspaceDetails;
}

const TaskTemplateListItem: FC<ListItemProps> = ({ task, workspace }) => {
  const navigateToTask = useNavigateToTask(task.id);
  const deleteTask = useDeleteTask();
  const [handleDelete, deleting] = useRunning(
    useCallback(async () => deleteTask(task.id), [deleteTask, task.id])
  );
  return (
    <Card key={task.id} size="small" className="bg-body-secondary">
      <Row align="middle">
        <Typography.Text style={{ flex: 1 }}>{task.name}</Typography.Text>
        <Can I="create" this={task}>
          <Link href={`${workspace.permalink}/create?template=${task.id}`}>
            <a>
              <Tooltip title="Create task from template">
                <Button
                  icon={<FileAddOutlined />}
                  type="text"
                  size="small"
                  className="text-secondary"
                />
              </Tooltip>
            </a>
          </Link>
        </Can>
        <Can I="update" this={task}>
          <Tooltip title="Update template">
            <Button
              icon={<EditOutlined />}
              type="text"
              size="small"
              className="text-secondary"
              onClick={navigateToTask}
            />
          </Tooltip>
        </Can>
        <Can I="delete" this={task}>
          <Tooltip title="Delete template">
            <Popconfirm
              icon={<DeleteOutlined style={{ color: Colors.grey.primary }} />}
              title="Delete Template?"
              okType="danger"
              okText="Delete"
              placement="bottomLeft"
              onConfirm={handleDelete}
            >
              <Button
                icon={<DeleteOutlined />}
                loading={deleting}
                type="text"
                size="small"
                className="text-secondary"
              />
            </Popconfirm>
          </Tooltip>
        </Can>
      </Row>
    </Card>
  );
};

const CreateTemplateButton: FC<
  Props & { values?: Partial<TaskFormValues> }
> = ({ workspace, values }) => (
  <Can
    I="create"
    this={{
      __typename: "Task",
      status: TaskStatus.TODO,
      workspaceId: workspace.id,
    }}
  >
    <Link
      href={`${
        workspace.permalink
      }/settings/templates/create?values=${encodeURIComponent(
        JSON.stringify({ template: true, ...values })
      )}`}
    >
      <a>
        <Button icon={<PlusOutlined />} type="primary">
          New Template
        </Button>
      </a>
    </Link>
  </Can>
);

export const WorkspaceSettingsTaskTemplates: FC<Props> = ({ workspace }) => {
  const templates = useWorkspaceTaskTemplates(workspace.id);

  const [suggestionTemplates, taskTemplates] = useMemo(
    () =>
      _.partition(
        templates,
        (t) => t.status === TaskStatus.COMMUNITY_SUGGESTIONS
      ),
    [templates]
  );

  return (
    <>
      <Skeleton loading={!templates}>
        <Typography.Paragraph type="secondary">
          Create reusable templates to make it easier to create tasks. For
          example, create a template for bug reports, feature requests or
          community suggestions.
        </Typography.Paragraph>

        <Typography.Paragraph strong>Task Templates</Typography.Paragraph>
        <Space direction="vertical">
          {taskTemplates?.map((t) => (
            <TaskTemplateListItem key={t.id} task={t} workspace={workspace} />
          ))}
          <CreateTemplateButton workspace={workspace} />
        </Space>

        {workspace.options?.showCommunitySuggestions && (
          <>
            <Divider />
            <Typography.Paragraph strong>
              Suggestion Templates
            </Typography.Paragraph>
            <Space direction="vertical">
              {suggestionTemplates?.map((t) => (
                <TaskTemplateListItem
                  key={t.id}
                  task={t}
                  workspace={workspace}
                />
              ))}
              <CreateTemplateButton
                workspace={workspace}
                values={{ status: TaskStatus.COMMUNITY_SUGGESTIONS }}
              />
            </Space>
          </>
        )}
      </Skeleton>
    </>
  );
};
