import { FolderOutlined } from "@ant-design/icons";
import { WorkspaceStatus } from "@dewo/app/graphql/types";
import { Button } from "antd";
import React, { FC, useCallback } from "react";

interface Props {
  value?: WorkspaceStatus;
  onChange?(status: WorkspaceStatus): void;
}

export const WorkspaceStatusButton: FC<Props> = ({
  value: status,
  onChange,
}) => {
  const handleClick = useCallback(
    () =>
      onChange?.(
        status === WorkspaceStatus.ARCHIVED
          ? WorkspaceStatus.ACTIVE
          : WorkspaceStatus.ARCHIVED
      ),
    [status, onChange]
  );
  return (
    <Button icon={<FolderOutlined />} onClick={handleClick}>
      {status === WorkspaceStatus.ARCHIVED ? "Unarchive" : "Archive"}
    </Button>
  );
};
