import { UserAvatar } from "@dewo/app/components/avatars/UserAvatar";
import { FormSection } from "@dewo/app/components/form/FormSection";
import {
  RulePermission,
  WorkspaceOverview,
  WorkspaceType,
} from "@dewo/app/graphql/types";
import { Avatar, Progress, Skeleton, Space, Typography } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { FC, useMemo } from "react";
import { useOrganizationUsers } from "../../organization/hooks";
import { useRolesWithAccess } from "../../rbac/hooks";

export const WorkspaceOverviewStats: FC<{
  workspace: WorkspaceOverview | undefined;
}> = ({ workspace }) => {
  const rolesWithAccess = useRolesWithAccess(
    workspace?.organizationId,
    workspace?.id
  );
  const { users } = useOrganizationUsers(workspace?.organizationId);
  const userById = _.keyBy(users, "id");
  const workspaceOwners = useMemo(
    () =>
      rolesWithAccess
        ?.filter(
          (role) =>
            role.userId! in userById &&
            role.rules.some(
              (r) =>
                r.permission === RulePermission.MANAGE_PROJECTS &&
                r.workspaceId === workspace?.id &&
                !r.inverted
            )
        )
        .map((role) => userById[role.userId!]),
    [userById, rolesWithAccess, workspace?.id]
  );

  const progressPercent =
    !workspace?.doneTaskCount || !workspace?.totalTaskCount
      ? 0
      : (workspace.doneTaskCount / workspace.totalTaskCount) * 100;

  const isProject = workspace?.type === WorkspaceType.PROJECT;

  if (!workspace) {
    return (
      <Skeleton
        active
        title={false}
        paragraph={{ rows: 2, style: { width: 200 } }}
      />
    );
  }

  const stats = [
    workspaceOwners?.length !== 0 && (
      <FormSection
        key="owners"
        label={`${isProject ? "Project" : "Space"} owners`}
        style={{ marginBottom: 0 }}
      >
        <Avatar.Group maxCount={3} size="small">
          {!workspaceOwners &&
            _.range(3).map((i) => <Skeleton.Avatar size="small" key={i} />)}
          {workspaceOwners?.map((user) => (
            <UserAvatar key={user.id} user={user} linkToProfile />
          ))}
        </Avatar.Group>
      </FormSection>
    ),
    !!workspace.contributors.length && (
      <FormSection
        key="contributors"
        label="Contributors"
        style={{ marginBottom: 0 }}
      >
        <Avatar.Group maxCount={3} size="small">
          {workspace?.contributors?.map((user) => (
            <UserAvatar key={user.id} user={user} linkToProfile />
          ))}
        </Avatar.Group>
      </FormSection>
    ),
    !!workspace.startAt && (
      <FormSection key="start" label="Start date" style={{ marginBottom: 0 }}>
        <Typography.Text type="secondary">
          {moment(workspace.startAt).format("ll")}
        </Typography.Text>
      </FormSection>
    ),
    !!workspace.endAt && (
      <FormSection key="end" label="End date" style={{ marginBottom: 0 }}>
        <Typography.Text type="secondary">
          {moment(workspace.endAt).format("ll")}
        </Typography.Text>
      </FormSection>
    ),
    isProject && (
      <FormSection key="progress" label="Progress" style={{ marginBottom: 0 }}>
        <Typography.Text type="secondary">
          {Math.round(progressPercent)}%
          <Progress
            type="circle"
            strokeWidth={14}
            percent={progressPercent}
            showInfo={false}
            width={22}
            style={{ marginTop: -2, marginLeft: 4 }}
          />
        </Typography.Text>
      </FormSection>
    ),
  ].filter((s) => !!s);

  if (!stats.length) return null;
  return (
    <Space align="start" size={["large", "middle"]} wrap>
      {stats}
    </Space>
  );
};
