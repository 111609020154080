import { WorkspaceDetails } from "@dewo/app/graphql/types";
import { Space } from "antd";
import React, { FC, useCallback } from "react";
import {
  useWorkspacePaymentMethods,
  useUpdatePaymentMethod,
} from "../../payment/hooks";
import { PaymentMethodSummary } from "../../payment/PaymentMethodSummary";
import { AddWorkspacePaymentMethodButton } from "../../payment/workspace/AddWorkspacePaymentMethodButton";

interface Props {
  workspace: WorkspaceDetails;
}

export const WorkspaceSettingsPaymentMethod: FC<Props> = ({ workspace }) => {
  const paymentMethods = useWorkspacePaymentMethods(workspace.id);
  const updatePaymentMethod = useUpdatePaymentMethod();
  const removePaymentMethod = useCallback(
    (pm) =>
      updatePaymentMethod({ id: pm.id, deletedAt: new Date().toISOString() }),
    [updatePaymentMethod]
  );

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        {paymentMethods?.map((paymentMethod) => (
          <PaymentMethodSummary
            key={paymentMethod.id}
            type={paymentMethod.type}
            address={paymentMethod.address}
            networkName={paymentMethod.network.name}
            onClose={() => removePaymentMethod(paymentMethod)}
          />
        ))}
        <AddWorkspacePaymentMethodButton
          key={paymentMethods?.length}
          workspaceId={workspace.id}
          children="Add Payment Method"
        />
      </Space>
    </>
  );
};
