import {
  Workspace,
  WorkspaceIntegrationFeature,
} from "@dewo/app/graphql/types";
import { useRunning, useToggle } from "@dewo/app/util/hooks";
import { Alert, Button, Form, Space, Typography } from "antd";
import * as Icons from "@ant-design/icons";
import { useForm, useWatch } from "antd/lib/form/Form";
import React, { FC, useCallback } from "react";
import { CreateIntegrationFeatureCard } from "../CreateIntegrationFeatureCard";
import {
  useCreateNotionWorkspaceIntegration,
  useNotionDatabases,
  useUpdateWorkspaceIntegration,
} from "../hooks";
import { WorkspaceIntegration } from "../../../graphql/types";
import { FormSection } from "@dewo/app/components/form/FormSection";
import { HeadlessCollapse } from "@dewo/app/components/HeadlessCollapse";
import * as Colors from "@ant-design/colors";
import { SelectNotionDatabaseFormItem } from "./SelectNotionDatabaseFormItem";
import { NotionIcon } from "@dewo/app/components/icons/Notion";

const getNotionIntegrationTypeTitle: Partial<
  Record<WorkspaceIntegrationFeature, string>
> = {
  [WorkspaceIntegrationFeature.NOTION_BOUNTY_FIELD]:
    "Link Dework bounties to Notion pages",
};

export interface FormValues {
  feature: WorkspaceIntegrationFeature;
  databaseIds?: string[];
}

interface Props {
  feature: WorkspaceIntegrationFeature;
  existingIntegrations: WorkspaceIntegration[];
  workspace: Workspace;
  disabled?: boolean;
}

export const CreateNotionIntegrationFeatureForm: FC<Props> = ({
  feature,
  existingIntegrations,
  workspace,
  disabled = false,
}) => {
  const [form] = useForm<FormValues>();
  const hasIntegrations = !!existingIntegrations.length;
  const expanded = useToggle(hasIntegrations);
  const databaseIds = useWatch("databaseIds", form);

  const notionDatabases = useNotionDatabases(workspace.organizationId);

  const updateIntegration = useUpdateWorkspaceIntegration();
  const handleDelete = useCallback(
    (id: string) =>
      updateIntegration({ id, deletedAt: new Date().toISOString() }),
    [updateIntegration]
  );

  const createIntegration = useCreateNotionWorkspaceIntegration();
  const submit = useCallback(
    async (values: FormValues) => {
      const databases = notionDatabases?.filter((d) =>
        values.databaseIds?.includes(d.id)
      );
      if (!databases) return;

      for (const database of databases) {
        await createIntegration({
          database,
          workspaceId: workspace.id,
          feature: values.feature,
        });
      }
      form.resetFields();
    },
    [form, notionDatabases, workspace.id, createIntegration]
  );
  const [handleSubmit, submitting] = useRunning(submit);

  const connectedCopy =
    `Connected to ${existingIntegrations?.length} ` +
    (existingIntegrations?.length === 1 ? "repo" : "repos");

  return (
    <CreateIntegrationFeatureCard
      headerTitle={getNotionIntegrationTypeTitle[feature]}
      headerIcon={
        <NotionIcon
          style={{ color: hasIntegrations ? Colors.purple[3] : undefined }}
        />
      }
      isConnected={hasIntegrations}
      connectedButtonCopy={connectedCopy}
      expanded={expanded}
      disabled={disabled}
      collapsedContent={
        hasIntegrations && (
          <Typography.Text className="font-semibold">
            <Typography.Text type="secondary">Connected to</Typography.Text>
            <Typography.Text style={{ marginLeft: 4 }}>
              {`${existingIntegrations
                ?.map(
                  (integration) =>
                    `${integration?.config.organization}/${integration?.config.repo}`
                )
                .join(", ")}`}
            </Typography.Text>
          </Typography.Text>
        )
      }
      onClick={expanded.toggle}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={handleSubmit}
      >
        <Form.Item name="feature" hidden initialValue={feature} />
        <Space direction="vertical">
          {/* <Typography.Paragraph type="secondary" style={{ marginBottom: 8 }}>
            Learn more about this integration{" "}
            <a
              href={deworkSocialLinks.gitbook.connectingToGithub}
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </Typography.Paragraph> */}
          {hasIntegrations && (
            <FormSection label={connectedCopy}>
              <Space direction="vertical">
                {existingIntegrations.map((integration) => {
                  const database = notionDatabases?.find(
                    (db) => db.id === integration.config.databaseId
                  );
                  return (
                    <Alert
                      key={integration.id}
                      type="info"
                      message={
                        <Typography.Text>
                          Connected to{" "}
                          <Typography.Text className="font-semibold">
                            {database?.name}
                          </Typography.Text>
                        </Typography.Text>
                      }
                      icon={<Icons.CheckCircleFilled />}
                      showIcon
                      closable={!!integration.id}
                      onClose={() => handleDelete(integration.id)}
                    />
                  );
                })}
              </Space>
            </FormSection>
          )}
          <FormSection label="Connect one or more databases">
            <SelectNotionDatabaseFormItem
              organizationId={workspace?.organizationId!}
              databases={notionDatabases}
            />
          </FormSection>
          <HeadlessCollapse expanded={!!databaseIds?.length}>
            <Button type="primary" htmlType="submit" loading={submitting}>
              Connect Notion
            </Button>
          </HeadlessCollapse>
        </Space>
      </Form>
    </CreateIntegrationFeatureCard>
  );
};
