import React, { ReactElement, useEffect, useMemo } from "react";
import { NextPage } from "next";
import { Layout, Tabs } from "antd";
import { useRouter } from "next/router";
import { WorkspaceHeader } from "@dewo/app/containers/workspace/overview/WorkspaceHeader";
import {
  useWorkspace,
  useWorkspaceDetails,
  useWorkspaceIdBySlug,
  useWorkspaceTasks,
} from "@dewo/app/containers/workspace/hooks";
import { Tab } from "@dewo/app/components/Tab";
import { WorkspaceSettings } from "@dewo/app/containers/workspace/settings/WorkspaceSettings";
import { WorkspaceAccessDeniedModal } from "@dewo/app/components/modals/WorkspaceAccessDeniedModal";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { WorkspaceSeo } from "@dewo/app/containers/seo/WorkspaceSeo";
import { useOrganizationBySlug } from "@dewo/app/containers/organization/hooks";
import { TaskCreateModal } from "@dewo/app/containers/task/TaskCreateModal";
import { TaskStatus } from "@dewo/app/graphql/types";
import moment from "moment";
import { WorkspaceTaskViewProvider } from "@dewo/app/containers/task/views/TaskViewContext";
import { TaskViewTabs } from "@dewo/app/containers/task/views/TaskViewTabs";
import { WorkspaceEmptyColumns } from "@dewo/app/containers/workspace/board/WorkspaceTaskBoard";
import { useIsEmbedded } from "@dewo/app/util/navigation";
import { TaskViewLayout } from "@dewo/app/containers/task/views/TaskViewLayout";

import { WorkspaceCommunitySuggestions } from "@dewo/app/containers/workspace/overview/WorkspaceCommunitySuggestions";
import { BulbOutlined, ExpandOutlined } from "@ant-design/icons";
import { WorkspaceOverview } from "@dewo/app/containers/workspace/overview/WorkspaceOverview";
import { useLocalState } from "@dewo/app/util/hooks";

const useRememberTab = () => {
  const router = useRouter();
  const workspaceSlug = router.query.workspaceSlug as string;
  const workspaceId = useWorkspaceIdBySlug(workspaceSlug); // resolves even if has no access
  const { workspace } = useWorkspace(workspaceId);
  const [remberedTab, setRememberedTab] = useLocalState<string>(
    `Workspace.${workspaceId}.lastTab`,
    "overview"
  );

  const currentTab = router.query.tab as string;
  const viewSlug = router.query.viewSlug as string | undefined;
  const taskId = router.query.taskId as string | undefined;
  const isCreatingTask = router.route.endsWith("/create");

  const tabToShow = currentTab ?? remberedTab;
  useEffect(() => {
    if (!workspace) return;

    if (taskId || isCreatingTask || viewSlug) {
      setRememberedTab("");
    } else if (tabToShow && tabToShow !== currentTab) {
      router.replace(`${workspace.permalink}/${tabToShow}`);
    } else if (currentTab) {
      setRememberedTab(currentTab);
    }
  }, [
    isCreatingTask,
    tabToShow,
    router,
    setRememberedTab,
    currentTab,
    taskId,
    viewSlug,
    workspace,
  ]);

  if (tabToShow === "") return undefined;

  return tabToShow;
};

const Page: NextPage = () => {
  const router = useRouter();
  const currentTab = useRememberTab();
  const isEmbedded = useIsEmbedded();

  const { organizationSlug, workspaceSlug } = router.query as {
    organizationSlug: string;
    workspaceSlug: string;
  };
  const { organization } = useOrganizationBySlug(organizationSlug);
  const workspaceId = useWorkspaceIdBySlug(workspaceSlug); // resolves even if has no access
  const { workspace, error } = useWorkspace(workspaceId);
  const details = useWorkspaceDetails(workspace?.id).workspace;
  const organizationId = organization?.id;
  const canEditWorkspace = usePermission("update", workspace);

  const forbiddenError = error?.message === "Forbidden resource";

  useEffect(() => {
    if (!workspaceSlug || !organizationSlug) {
      router.replace("/");
    }
  }, [router, workspaceSlug, organizationSlug]);

  const createFormInitialValues = useMemo(() => {
    if (!router.query.values) return { status: TaskStatus.TODO };
    const parsed = JSON.parse(router.query.values as string);
    return {
      status: TaskStatus.TODO,
      ...parsed,
      dueDate: !!parsed.dueDate ? moment(parsed.dueDate) : undefined,
    };
  }, [router.query.values]);

  const tasks = useWorkspaceTasks(workspaceId, "cache-and-network");

  const extraTabsAfter = useMemo(
    () =>
      [
        !!workspaceId && !!details?.options?.showCommunitySuggestions && (
          <Tabs.TabPane
            tab={
              <Tab icon={<BulbOutlined />} children="Community Suggestions" />
            }
            key="community"
            className="dewo-layout-padding-vertical"
          >
            <WorkspaceCommunitySuggestions workspaceId={workspaceId} />
          </Tabs.TabPane>
        ),
      ].filter((t): t is ReactElement => !!t),
    [details?.options?.showCommunitySuggestions, workspaceId]
  );
  const extraTabsBefore = useMemo(
    () =>
      [
        !!workspace && (
          <Tabs.TabPane
            tab={<Tab icon={<ExpandOutlined />} children="Overview" />}
            key="overview"
          >
            <WorkspaceOverview workspace={workspace} />
          </Tabs.TabPane>
        ),
      ].filter((t): t is ReactElement => !!t),
    [workspace]
  );

  if (!workspaceSlug || !organizationSlug) {
    return null;
  }

  return (
    <Layout>
      <Layout.Content style={{ display: "flex", flexDirection: "column" }}>
        {!isEmbedded && <WorkspaceHeader workspaceId={workspaceId} />}
        <Layout.Content style={{ flex: 1 }}>
          <WorkspaceTaskViewProvider workspaceId={workspaceId}>
            {isEmbedded ? (
              <TaskViewLayout tasks={tasks} />
            ) : (
              <TaskViewTabs
                workspaceId={workspaceId}
                activeKey={currentTab}
                extraTabsBefore={extraTabsBefore}
                extraTabsAfter={extraTabsAfter}
                hiddenTabs={[
                  !!details && canEditWorkspace && (
                    <Tabs.TabPane
                      key="settings"
                      className="max-w-lg w-full dewo-layout-padding-vertical"
                      style={{ paddingTop: 24 }}
                    >
                      <WorkspaceSettings workspace={details} />
                    </Tabs.TabPane>
                  ),
                ].filter((t): t is ReactElement => !!t)}
              >
                <TaskViewLayout empty={WorkspaceEmptyColumns} tasks={tasks} />
              </TaskViewTabs>
            )}
          </WorkspaceTaskViewProvider>
        </Layout.Content>
      </Layout.Content>
      <WorkspaceAccessDeniedModal
        visible={forbiddenError}
        workspaceId={workspaceId}
        organizationId={organizationId}
      />
      {!!details && <WorkspaceSeo workspace={details} />}
      {!!workspace && (
        <WorkspaceTaskViewProvider workspaceId={workspaceId}>
          <TaskCreateModal
            workspaceId={workspace.id}
            templateId={router.query.template as string | undefined}
            initialValues={createFormInitialValues}
            visible={router.route.endsWith("/create")}
            onDone={() => router.push(workspace.permalink)}
            onCancel={() => router.push(workspace.permalink)}
          />
        </WorkspaceTaskViewProvider>
      )}
    </Layout>
  );
};

export default Page;
