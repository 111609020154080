import { BulbOutlined } from "@ant-design/icons";
import {
  SearchTasksInput,
  TaskStatus,
  TaskViewSortByDirection,
  TaskViewSortByField,
} from "@dewo/app/graphql/types";
import { Typography } from "antd";
import React, { FC, useMemo } from "react";
import { TaskBoardColumnEmpty } from "../board/TaskBoardColumnEmtpy";
import { useTaskViewLayoutData } from "../views/hooks";
import { useTaskViewSearchContext } from "../views/TaskViewSearchContext";
import { TaskVotingList } from "./TaskVotingList";

interface Props {
  workspaceIds?: string[];
  templateId?: string;
  sortBy: TaskViewSortByField;
  emptyMessage?: string;
}

const EmptyState: FC = ({ children }) => (
  <div style={{ padding: 24 }}>
    <TaskBoardColumnEmpty
      title={
        <Typography.Paragraph type="secondary">{children}</Typography.Paragraph>
      }
      icon={<BulbOutlined />}
    />
  </div>
);

export const CommunitySuggestionsList: FC<Props> = ({
  workspaceIds,
  templateId,
  sortBy,
  emptyMessage,
}) => {
  const search = useTaskViewSearchContext();
  const [data] = useTaskViewLayoutData(
    useMemo(
      (): SearchTasksInput[] => [
        {
          templateIds: !!templateId ? [templateId] : undefined,
          workspaceIds,
          sortBy: { field: sortBy, direction: TaskViewSortByDirection.DESC },
          name: !!search.query ? search.query : undefined,
          statuses: [TaskStatus.COMMUNITY_SUGGESTIONS],
        },
      ],
      [workspaceIds, templateId, sortBy, search.query]
    )
  );

  return (
    <TaskVotingList
      data={data}
      renderEmpty={
        !!emptyMessage
          ? () => <EmptyState children={emptyMessage} />
          : undefined
      }
    />
  );
};
