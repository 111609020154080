import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import { Can } from "@dewo/app/contexts/PermissionsContext";
import { TaskStatus, TaskViewSortByField } from "@dewo/app/graphql/types";
import { useToggle } from "@dewo/app/util/hooks";
import { Button, Dropdown, Menu, Row, Typography } from "antd";
import { useRouter } from "next/router";
import React, { FC, useMemo, useState } from "react";
import { TaskCreateModal } from "../../task/TaskCreateModal";
import { CommunitySuggestionsList } from "../../task/voting/CommunitySuggestionsList";

interface Props {
  workspaceId: string;
}

const sortByTextMap: Partial<Record<TaskViewSortByField, string>> = {
  [TaskViewSortByField.votes]: "Trending",
  [TaskViewSortByField.createdAt]: "Recent",
};

export const WorkspaceCommunitySuggestions: FC<Props> = ({ workspaceId }) => {
  const [sortBy, setSortBy] = useState(TaskViewSortByField.votes);
  const workspaceIds = useMemo(() => [workspaceId], [workspaceId]);

  const createTaskToggle = useToggle();
  const router = useRouter();
  return (
    <>
      <Row justify="space-between" style={{ marginTop: 24 }}>
        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu selectedKeys={[sortBy]}>
              {(Object.keys(sortByTextMap) as TaskViewSortByField[]).map(
                (key) => (
                  <Menu.Item key={key} onClick={() => setSortBy(key)}>
                    {sortByTextMap[key]}
                  </Menu.Item>
                )
              )}
            </Menu>
          }
        >
          <Typography.Title level={4}>
            {sortByTextMap[sortBy]}
            <DownOutlined style={{ paddingLeft: 8 }} />
          </Typography.Title>
        </Dropdown>
        <Can
          I="create"
          this={{
            __typename: "Task",
            workspaceId,
            // @ts-ignore
            ...{ ownerIds: [] },
            status: TaskStatus.COMMUNITY_SUGGESTIONS,
          }}
        >
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={createTaskToggle.toggleOn}
          >
            Add a suggestion
          </Button>
        </Can>
      </Row>

      <CommunitySuggestionsList
        workspaceIds={workspaceIds}
        emptyMessage="This workspace doesn't have any suggestions yet. Be the first to add one!"
        sortBy={sortBy}
      />

      <TaskCreateModal
        workspaceId={workspaceId}
        templateId={router.query.template as string | undefined}
        initialValues={{ status: TaskStatus.COMMUNITY_SUGGESTIONS }}
        visible={createTaskToggle.isOn}
        onCancel={createTaskToggle.toggleOff}
        onDone={createTaskToggle.toggleOff}
      />
    </>
  );
};
