import React, { FC, useCallback } from "react";
import { Avatar, Button, Modal, Row, Typography } from "antd";
import { IncognitoIcon } from "@dewo/app/components/icons/Incognito";
import Link from "next/link";
import { useWorkspace } from "@dewo/app/containers/workspace/hooks";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { LoginModal } from "@dewo/app/containers/auth/LoginModal";
import { useRouter } from "next/router";

interface Props {
  visible: boolean;
  workspaceId?: string;
}

export const CreateTaskAccessDeniedModal: FC<Props> = ({
  visible,
  workspaceId,
}) => {
  const { workspace } = useWorkspace(workspaceId);
  const { user } = useAuthContext();

  const router = useRouter();
  const openWorkspace = useCallback(
    () => router.push(workspace?.permalink ?? "/"),
    [router, workspace?.permalink]
  );

  if (!user) {
    return <LoginModal visible={visible} onClose={openWorkspace} />;
  }

  return (
    <Modal
      visible={visible}
      footer={null}
      style={{ textAlign: "center" }}
      closable={false}
    >
      <Avatar
        icon={<IncognitoIcon style={{ width: 72, height: 72 }} />}
        size={96}
        style={{ display: "grid", placeItems: "center" }}
        className="mx-auto"
      />
      <Typography.Title level={4} style={{ marginTop: 16 }}>
        Access denied
      </Typography.Title>
      <Typography.Paragraph type="secondary">
        You cannot create tasks in this workspace. Ask someone who can manage
        the workspace to invite you.
      </Typography.Paragraph>
      <Row style={{ gap: 8, justifyContent: "center" }}>
        <Link href={workspace?.permalink ?? "/"}>
          <a>
            <Button>Close</Button>
          </a>
        </Link>
      </Row>
    </Modal>
  );
};
