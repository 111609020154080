import {
  Workspace,
  WorkspaceOverview as WorkspaceOverviewFragment,
  WorkspaceSectionLayout,
  WorkspaceStatus,
} from "@dewo/app/graphql/types";
import { Button, Col, Grid, Row, Space, Typography } from "antd";
import React, { FC, useCallback, useMemo } from "react";
import { useWorkspaceOverview } from "../../organization/hooks";
import { TaskDiscoveryList } from "../../../components/task/TaskDiscoveryList";
import { WorkspaceOverviewStats } from "./WorkspaceOverviewStats";
import { Can, usePermission } from "@dewo/app/contexts/PermissionsContext";
import { FormOutlined, PlusOutlined } from "@ant-design/icons";
import { CreateWorkspaceButton } from "../create/CreateWorkspaceButton";
import Link from "next/link";
import { RichMarkdownEditor } from "@dewo/app/components/richMarkdown/RichMarkdownEditor";
import { RichMarkdownViewer } from "@dewo/app/components/richMarkdown/RichMarkdownViewer";
import { WorkspaceActivityFeed } from "../activityFeed/WorkspaceActivityFeed";
import { useWorkspaceDetails, useUpdateWorkspace } from "../hooks";
import { MilestoneOverview } from "./MilestoneOverview";
import { OverviewWorkspaceSection } from "../../organization/overview/workspaces/OverviewWorkspaceSection";

interface Props {
  workspace: Workspace | undefined;
}

export const WorkspaceOverview: FC<Props> = ({ workspace }) => {
  const organizationId = workspace?.organizationId;

  const overview = useWorkspaceOverview(workspace?.id)?.workspace;
  const details = useWorkspaceDetails(workspace?.id).workspace;
  const children = useMemo(
    () =>
      overview?.node.workspaceChildren
        .map((edge) => edge.node.workspace)
        .filter(
          (w): w is WorkspaceOverviewFragment =>
            !!w && w.status !== WorkspaceStatus.ARCHIVED
        ),
    [overview?.node.workspaceChildren]
  );
  const canEditWorkspace = usePermission("update", workspace);

  const descriptionIsEmpty = !workspace?.description?.replaceAll("/", "").trim()
    .length;

  const screens = Grid.useBreakpoint();

  const updateWorkspace = useUpdateWorkspace();
  const handleDescriptionChange = useCallback(
    (description: string) =>
      updateWorkspace({ id: workspace?.id!, description }),
    [workspace?.id, updateWorkspace]
  );

  return (
    <Row
      className="max-w-xxl w-full dewo-layout-padding-vertical"
      style={{ paddingTop: 32, paddingBottom: 64 }}
      gutter={[screens.xs ? 0 : 48, 24]}
    >
      <Col
        span={24}
        lg={16}
        style={{ display: "flex", flexDirection: "column", rowGap: 24 }}
        className={!screens.xs ? "dewo-divider-right" : undefined}
      >
        <Space size="small" direction="vertical">
          <Typography.Title level={5} style={{ margin: 0 }}>
            About
            {!!canEditWorkspace && (
              <Link href={`${workspace?.permalink}/settings/general`}>
                <a>
                  <Button
                    style={{ marginLeft: 8 }}
                    icon={<FormOutlined />}
                    size="small"
                    className="text-secondary"
                    type="text"
                  />
                </a>
              </Link>
            )}
          </Typography.Title>

          <WorkspaceOverviewStats workspace={overview} />

          {canEditWorkspace ? (
            <div style={{ marginLeft: -5 /* editor padding + border */ }}>
              <RichMarkdownEditor
                mode="update"
                placeholder="No description..."
                initialValue={workspace?.description ?? ""}
                onSave={handleDescriptionChange}
                editable
              />
            </div>
          ) : (
            <>
              {descriptionIsEmpty ? (
                <Typography.Text type="secondary">
                  No description...
                </Typography.Text>
              ) : (
                <div style={{ opacity: 0.7 }}>
                  <RichMarkdownViewer value={workspace?.description!} />
                </div>
              )}
            </>
          )}
        </Space>

        {!!workspace && <MilestoneOverview workspaceId={workspace.id} />}

        {(canEditWorkspace || !!children?.length) &&
          !workspace?.parentId &&
          !!children &&
          !!organizationId &&
          !!workspace && (
            <OverviewWorkspaceSection
              title="Spaces"
              workspaces={children}
              layout={WorkspaceSectionLayout.GRID}
              extra={[
                <div key="flex" style={{ flex: 1 }} />,
                !!workspace && !workspace.parentId && (
                  <Can
                    key="create"
                    I="create"
                    this={{
                      __typename: "Workspace",
                      organizationId,
                      parentId: workspace?.id,
                    }}
                  >
                    <CreateWorkspaceButton
                      organizationId={organizationId}
                      initialValues={
                        workspace.id ? { parentId: workspace.id } : undefined
                      }
                      type="primary"
                      size="small"
                      icon={<PlusOutlined />}
                      children="Create"
                    />
                  </Can>
                ),
              ]}
            />
          )}
        {!!overview?.openTaskCount && (
          <TaskDiscoveryList
            organizationId={organizationId}
            workspaceId={overview.id}
          />
        )}
      </Col>
      {!!details && (!!details.activityThread || canEditWorkspace) && (
        <Col span={24} lg={8}>
          <WorkspaceActivityFeed workspace={details} />
        </Col>
      )}
    </Row>
  );
};
