import { WorkspaceRole } from "@dewo/app/graphql/types";

export const workspaceRoleToString: Record<WorkspaceRole, string> = {
  [WorkspaceRole.ADMIN]: "Manage Workspace",
  [WorkspaceRole.CONTRIBUTOR]: "View Workspace",
};

export const workspaceRoleDescription: Record<WorkspaceRole, string> = {
  [WorkspaceRole.ADMIN]: [
    "Manage Space permission can:",
    "- do everything the View Space permission can",
    "- manage all tasks, applications, submissions",
    "- manage Space settings",
  ].join("\n"),
  [WorkspaceRole.CONTRIBUTOR]: [
    "View Space permission can:",
    "- view private Space and its tasks",
    "- apply to tasks",
    "- manage tasks they're assigned to or reviewing",
    "- create and vote on community suggestions",
  ].join("\n"),
};
