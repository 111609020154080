import { UserAvatar } from "@dewo/app/components/avatars/UserAvatar";
import { RichMarkdownViewer } from "@dewo/app/components/richMarkdown/RichMarkdownViewer";
import { Thread, ThreadMessage } from "@dewo/app/graphql/types";
import { Card, Skeleton, Space, Typography } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import _ from "lodash";
import moment from "moment";
import React, { FC, ReactNode } from "react";
import { ThreadMessageOptionButton } from "./ThreadMessageOptionButton";

interface Props {
  thread: Thread;
  message: ThreadMessage;
  header?: ReactNode;
  extraMenuItems?: ItemType[];
}

export const ThreadMessageListItem: FC<Props> = ({
  thread,
  message,
  header,
  extraMenuItems,
}) => {
  const screens = useBreakpoint();
  return (
    <Card
      size="small"
      bordered={false}
      title={header}
      extra={
        <Space>
          <Typography.Text type="secondary">
            {moment(message.createdAt).fromNow()}
          </Typography.Text>
          <UserAvatar size="small" user={message.sender} />
          <ThreadMessageOptionButton
            thread={thread}
            message={message}
            extraMenuItems={extraMenuItems}
          />
        </Space>
      }
      style={{ marginBottom: 8 }}
      bodyStyle={{ paddingTop: 8, paddingBottom: 12 }}
      className="bg-body-secondary"
    >
      {!_.isEmpty(screens) ? (
        <RichMarkdownViewer value={message.content} />
      ) : (
        <Skeleton.Button active />
      )}
    </Card>
  );
};
