import { Button, Dropdown, Menu, Skeleton, Space, Typography } from "antd";
import React, { FC, useMemo } from "react";
import { useWorkspace } from "../hooks";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { useOrganizationIntegrations } from "../../organization/hooks";
import { OrganizationIntegrationType } from "@dewo/app/graphql/types";
import { DebugMenu } from "@dewo/app/components/DebugMenu";
import { WorkspaceInviteButton } from "../../invite/WorkspaceInviteButton";
import { CoordinapeMetamaskConnectButton } from "../../integrations/coordinape/CoordinapeMetamaskConnectButton";
import { CoordinapeIcon } from "@dewo/app/components/icons/Coordinape";
import { ConnectUsingDiscordRolesButton } from "../../auth/buttons/ConnectUsingDiscordRolesButton";
import { ConnectOrganizationToDiscordButton } from "../../integrations/discord/ConnectOrganizationToDiscordButton";
import { Header } from "../../navigation/header/Header";
import Link from "next/link";
import { useWorkspaceSettingsTabs } from "../settings/WorkspaceSettings";
import { BlockButton } from "@dewo/app/components/buttons/BlockButton";
import styles from "./WorkspaceHeader.module.less";
import classNames from "classnames";
import { WorkspaceName } from "../WorkspaceName";
import {
  DownOutlined,
  SafetyOutlined,
  SettingOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { useExperiment } from "@dewo/app/util/analytics/useExperiment";
import { MiddleDot } from "@dewo/app/components/MiddleDot";
import { useLocalState } from "@dewo/app/util/hooks";

interface Props {
  workspaceId?: string;
}

// Hardcoded discord permissions. TODO: maybe move to an API route?
const MANAGE_CHANNELS = BigInt(0x10),
  CREATE_INSTANT_INVITE = BigInt(0x1),
  MANAGE_ROLES = BigInt(0x10000000),
  SEND_MESSAGES = BigInt(0x800),
  CREATE_PRIVATE_THREADS = BigInt(0x1000000000),
  MANAGE_THREADS = BigInt(0x400000000),
  ADMINISTRATOR = BigInt(0x8);

export const WorkspaceHeader: FC<Props> = ({ workspaceId }) => {
  const { workspace } = useWorkspace(workspaceId);
  const organizationId = workspace?.organizationId;

  const canEdit = usePermission("update", workspace);
  const canEditOrg = usePermission("update", "Organization");
  const redirect = `${workspace?.permalink}/settings/discord`;

  const integrations = useOrganizationIntegrations(organizationId);
  const discordIntegration = useMemo(
    () =>
      integrations?.find((i) => i.type === OrganizationIntegrationType.DISCORD),
    [integrations]
  );
  const discordPermissions = useMemo(
    () =>
      discordIntegration?.config?.permissions
        ? BigInt(discordIntegration.config.permissions)
        : undefined,
    [discordIntegration]
  );
  const hasCorrectPermissions =
    discordPermissions &&
    (discordPermissions & ADMINISTRATOR ||
      (discordPermissions & CREATE_INSTANT_INVITE &&
        discordPermissions & MANAGE_CHANNELS &&
        discordPermissions & SEND_MESSAGES &&
        discordPermissions & CREATE_PRIVATE_THREADS &&
        discordPermissions & MANAGE_THREADS &&
        discordPermissions & MANAGE_ROLES));

  const settingsTabs = useWorkspaceSettingsTabs(workspace);

  enum ExperimentVariants {
    SettingsOnly = "SettingsOnly",
    IntegrationsAndAccess = "IntegrationsAndAccess",
  }

  const buttonsExperiment = useExperiment({
    name: "Workspace Headings: Settings buttons",
    variants: {
      [ExperimentVariants.SettingsOnly]: "Settings Only",
      [ExperimentVariants.IntegrationsAndAccess]:
        "Integrations + Access & Permissions",
    },
  });

  const [showSettingsDot, setShowSettingsDot] = useLocalState<"true" | "false">(
    `Workspace.${workspaceId}.showSettingsDot`,
    "true"
  );

  return (
    <Header
      className={classNames(styles.workspaceHeader, "bg-body-secondary")}
      style={{ paddingBottom: 0 }}
      title={
        !!workspace ? (
          <Space wrap>
            <Typography.Title level={4} style={{ margin: 0 }}>
              <WorkspaceName workspace={workspace} />
            </Typography.Title>
            {!!canEdit && (
              <Dropdown
                trigger={["click"]}
                overlay={
                  <Menu>
                    {settingsTabs.map((settingsTab) => (
                      <Menu.Item key={settingsTab.key} icon={settingsTab.icon}>
                        <Link
                          href={`${workspace.permalink}/settings/${settingsTab.key}`}
                        >
                          {settingsTab.title}
                        </Link>
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <Button
                  type="text"
                  className="text-secondary"
                  icon={<SettingOutlined />}
                  style={{ paddingLeft: 8, paddingRight: 8 }}
                >
                  <DownOutlined />
                </Button>
              </Dropdown>
            )}
            {!!workspace && organizationId && (
              <Space wrap>
                <DebugMenu
                  workspaceId={workspace.id}
                  organizationId={organizationId}
                />
                {/* <FollowOrganizationButton organizationId={organizationId} /> */}
                <WorkspaceInviteButton workspaceId={workspace.id} />
                {canEditOrg && !discordIntegration && (
                  <ConnectOrganizationToDiscordButton
                    name="Connect organization to Discord from Workspace Header"
                    organizationId={workspace.organizationId}
                    redirect={redirect}
                  >
                    Connect to Discord
                  </ConnectOrganizationToDiscordButton>
                )}
                {canEditOrg && !!discordIntegration && !hasCorrectPermissions && (
                  <ConnectOrganizationToDiscordButton
                    name="Update Discord permissions from Workspace Header"
                    organizationId={workspace.organizationId}
                    redirect={redirect}
                  >
                    Update Discord Permissions
                  </ConnectOrganizationToDiscordButton>
                )}
                <ConnectUsingDiscordRolesButton
                  workspaceId={workspaceId}
                  organizationId={organizationId}
                  name="Connect user with Discord from Workspace Header"
                  children="Connect with Discord"
                />
                <CoordinapeMetamaskConnectButton
                  icon={<CoordinapeIcon />}
                  organizationId={organizationId}
                />
              </Space>
            )}
          </Space>
        ) : (
          <Skeleton.Button active style={{ width: 200 }} />
        )
      }
      extra={
        !!workspace &&
        organizationId && (
          <Space
            align="center"
            style={{ height: "100%", justifyContent: "end" }}
          >
            {buttonsExperiment === ExperimentVariants.SettingsOnly ? (
              !!canEdit && (
                <BlockButton
                  className={styles.extraButtons}
                  href={`${workspace.permalink}/settings`}
                  type="text"
                  icon={<SettingOutlined />}
                  name="Workspace Header: settings"
                  onClick={() => setShowSettingsDot("false")}
                >
                  Settings
                  {showSettingsDot === "true" && <MiddleDot color="green" />}
                </BlockButton>
              )
            ) : (
              <>
                {!!canEdit && !workspace.parentId && (
                  <BlockButton
                    className={styles.extraButtons}
                    href={`${workspace.permalink}/settings/discord`}
                    type="text"
                    icon={<ShareAltOutlined />}
                    name="Workspace Header: integrations"
                  >
                    Integrations
                  </BlockButton>
                )}
                {!!canEdit && (
                  <BlockButton
                    className={styles.extraButtons}
                    href={`${workspace.permalink}/settings/access`}
                    type="text"
                    icon={<SafetyOutlined />}
                    name="Workspace Header: access"
                  >
                    Access & Permissions
                  </BlockButton>
                )}
              </>
            )}
          </Space>
        )
      }
    />
  );
};
