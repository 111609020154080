import React, { useCallback, useMemo, useState } from "react";
import { Button, Form, Tag, Typography } from "antd";
import {
  Workspace,
  WorkspaceIntegrationFeature,
  WorkspaceIntegrationType,
} from "@dewo/app/graphql/types";
import { useMissingPermissions, useUpdateWorkspaceIntegration } from "../hooks";
import {
  CreateDiscordIntegrationPayload,
  FormValues,
} from "./CreateDiscordIntegrationForm";
import { useRunningCallback, useToggle } from "@dewo/app/util/hooks";
import { useForm } from "antd/lib/form/Form";
import { CreateIntegrationFeatureCard } from "../CreateIntegrationFeatureCard";
import { DiscordIcon } from "@dewo/app/components/icons/Discord";
import { SelectDiscordChannelFormItem } from "./SelectDiscordChannelFormItem";
import * as Colors from "@ant-design/colors";
import { deworkSocialLinks } from "@dewo/app/util/constants";
import { useWorkspaceIntegrations } from "../../workspace/hooks";
import { useOrganizationDiscordChannels } from "../../organization/hooks";

const DiscordWorkspaceIntegrationFeatureTitle: Partial<
  Record<WorkspaceIntegrationFeature, string>
> = {
  [WorkspaceIntegrationFeature.DISCORD_POST_TASK_UPDATES_TO_THREAD_PER_TASK]:
    "Create a thread when a task is assigned to someone",
  [WorkspaceIntegrationFeature.DISCORD_POST_STATUS_BOARD_MESSAGE]:
    "Post a status board with all open tasks",
  [WorkspaceIntegrationFeature.DISCORD_POST_COMMUNITY_SUGGESTIONS_STATUS_BOARD_MESSAGE]:
    "Post a status board with all community suggestions",
  [WorkspaceIntegrationFeature.DISCORD_POST_NEW_TASKS_TO_CHANNEL]:
    "Send a message when a task is created",
  [WorkspaceIntegrationFeature.DISCORD_POST_COMMUNITY_SUGGESTIONS_TO_CHANNEL]:
    "Send a message when a community suggestion is created",
  [WorkspaceIntegrationFeature.DISCORD_POST_ACTIVITY_FEED]:
    "Send a message when the activity feed is updated",
};

interface Props {
  organizationId: string;
  recommended?: boolean;
  feature: WorkspaceIntegrationFeature;
  disabled?: boolean;
  onSubmit(payload: CreateDiscordIntegrationPayload): Promise<void>;
  workspace: Workspace;
}

export const CreateDiscordIntegrationFeatureForm = ({
  organizationId,
  feature,
  disabled = false,
  recommended,
  onSubmit,
  workspace,
}: Props) => {
  const [form] = useForm<FormValues>();
  const [values, setValues] = useState<Partial<FormValues>>({});

  const allWorkspaceIntegrations = useWorkspaceIntegrations(workspace.id);
  const discordWorkspaceIntegrations = useMemo(
    () =>
      allWorkspaceIntegrations?.filter(
        (i) => i.type === WorkspaceIntegrationType.DISCORD
      ),
    [allWorkspaceIntegrations]
  );
  const existingIntegration = discordWorkspaceIntegrations?.find(
    (i) => i.feature === feature
  );
  const isConnected = !!existingIntegration;
  const expanded = useToggle(isConnected || (recommended && !disabled));
  const { value: channels } = useOrganizationDiscordChannels({
    organizationId: workspace.organizationId,
  });

  const missingPermissions = useMissingPermissions(
    channels,
    feature,
    values.discordChannelId
  );

  const handleChange = useCallback((_, values: Partial<FormValues>) => {
    setValues(values);
  }, []);
  const updateIntegration = useUpdateWorkspaceIntegration();
  const handleDisconnect = useCallback(async () => {
    try {
      await updateIntegration({
        id: existingIntegration?.id!,
        deletedAt: new Date().toISOString(),
      });
    } finally {
      form.resetFields();
      expanded.toggleOff();
    }
  }, [form, existingIntegration, updateIntegration, expanded]);
  const [handleSubmit, submitting] = useRunningCallback(
    async (values: FormValues) => {
      const channel = channels?.find((c) => c.id === values.discordChannelId);
      if (!channel) return;
      await onSubmit({ channel, feature });
    },
    [channels, feature, onSubmit]
  );

  return (
    <CreateIntegrationFeatureCard
      headerTitle={DiscordWorkspaceIntegrationFeatureTitle[feature]}
      headerIcon={
        <DiscordIcon style={{ color: isConnected && Colors.purple[3] }} />
      }
      isConnected={isConnected}
      connectedButtonCopy={"Disconnect"}
      expanded={expanded}
      disabled={disabled}
      onClick={isConnected ? handleDisconnect : expanded.toggle}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onValuesChange={handleChange}
        onFinish={handleSubmit}
      >
        {isConnected ? (
          <Typography.Text className="font-semibold">
            <Typography.Text type="secondary">Connected to</Typography.Text>
            <Typography.Text style={{ marginLeft: 4 }}>
              {`#${existingIntegration?.config.name}`}
            </Typography.Text>
          </Typography.Text>
        ) : (
          <>
            <Typography.Paragraph type="secondary" style={{ marginBottom: 8 }}>
              {recommended && <Tag color="green">Recommended!</Tag>}
              Learn more about this integration{" "}
              <a
                href={deworkSocialLinks.gitbook.connectingToDiscord}
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </Typography.Paragraph>
            <SelectDiscordChannelFormItem
              organizationId={organizationId}
              missingPermissions={missingPermissions}
              feature={feature}
              disabled={isConnected}
            />
            <Button
              type="primary"
              htmlType="submit"
              loading={submitting}
              hidden={isConnected}
              disabled={!values.discordChannelId}
            >
              Connect
            </Button>
          </>
        )}
      </Form>
    </CreateIntegrationFeatureCard>
  );
};
