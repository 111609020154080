import { WorkspaceDetails, WorkspaceTokenGate } from "@dewo/app/graphql/types";
import { Alert, Space } from "antd";
import React, { FC, useCallback } from "react";
import { useDeleteWorkspaceTokenGate } from "../hooks";
import { CreateWorkspaceTokenGate } from "./CreateWorkspaceTokenGate";
import { workspaceRoleToString } from "./strings";

interface Props {
  workspace: WorkspaceDetails;
}

export const WorkspaceSettingsTokenGating: FC<Props> = ({ workspace }) => {
  const deleteWorkspaceTokenGate = useDeleteWorkspaceTokenGate();
  const handleDeleteTokenGate = useCallback(
    async (tokenGate: WorkspaceTokenGate) => {
      await deleteWorkspaceTokenGate({
        workspaceId: workspace.id,
        tokenId: tokenGate.token.id,
        role: tokenGate.role,
      });
    },
    [deleteWorkspaceTokenGate, workspace.id]
  );

  return (
    <>
      <Space direction="vertical" style={{ width: "100%", marginBottom: 24 }}>
        {workspace.tokenGates.map((tokenGate) => (
          <Alert
            message={`Users with ${tokenGate.token.name} (${
              tokenGate.token.symbol
            }) in their wallets can join this workspace as ${
              workspaceRoleToString[tokenGate.role]
            }`}
            type="success"
            showIcon
            closable
            onClose={() => handleDeleteTokenGate(tokenGate)}
            key={tokenGate.id}
          />
        ))}
      </Space>

      <CreateWorkspaceTokenGate
        key={workspace.tokenGates.length}
        workspace={workspace}
      />
    </>
  );
};
